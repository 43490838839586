import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import {
  VerticalLine,
  JobDiv,
  ProfileCircle,
  TitleText,
} from "./StyledComponents";
import { Row, Col, Container, Button } from "react-bootstrap";
import EducationDetailsModal from "./EducationDetailsModal";
import SkillFilterModal from "./SkillFilterModal"; // Import the new component
import "./ProfessionalExperience.css";

const calculateInitialLines = (isMobile, jobCount, descriptionAdjustment) => {
  const lines = Array.from({ length: jobCount }, (_, index) => ({
    left: isMobile ? "50%" : `${45 + (index % 2) * 10}%`,
    top: isMobile
      ? `${
          250 * index +
          (index === 0 ? 0 : 330) +
          (index === 0 ? 0 : descriptionAdjustment)
        }px`
      : `${
          250 * index +
          (index === 0 ? 0 : 130) +
          (index === 0 ? 0 : descriptionAdjustment)
        }px`,
    height: "0px",
    endHeight: isMobile
      ? `${index === 0 ? 430 + descriptionAdjustment : 100}px`
      : `${index === 0 ? 230 + descriptionAdjustment : 100}px`,
  }));

  lines.length > 0 &&
    lines.push({
      left: isMobile ? "50%" : `${45 + (jobCount % 2) * 10}%`,
      top: isMobile
        ? `${330 + 250 * jobCount + descriptionAdjustment}px`
        : `${115 + 252 * jobCount + descriptionAdjustment}px`,
      height: "0px",
      endHeight: isMobile ? `${500}px` : `${700}px`,
    });

  return lines;
};

const Education = ({ isAnimating, education }) => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [visibleDivs, setVisibleDivs] = useState({});
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [descriptionAdjustment, setDescriptionAdjustment] = useState(0);

  const normalizeSkill = (skill) =>
    skill
      .trim()
      .toLowerCase()
      .replace(/\s+/g, " ")
      .replace(/(^|\s)\S/g, (l) => l.toUpperCase());

  const uniqueSkills = useMemo(() => {
    return [
      ...new Set(
        education.flatMap((job) =>
          job.skills.map((skill) => normalizeSkill(skill))
        )
      ),
    ].sort();
  }, [education]);

  const filterededucation = useMemo(() => {
    if (selectedSkills.length === 0) return education;
    const normalizedSelectedSkills = selectedSkills.map((skill) =>
      skill.toLowerCase()
    );
    return education.filter((job) =>
      normalizedSelectedSkills.every((selectedSkill) =>
        job.skills.some(
          (educationkill) => educationkill.toLowerCase() === selectedSkill
        )
      )
    );
  }, [selectedSkills, education]);

  const [lines, setLines] = useState(
    calculateInitialLines(
      window.innerWidth <= 991,
      filterededucation.length,
      descriptionAdjustment
    )
  );

  const handleAnimation = useCallback(() => {
    filterededucation.forEach((_, index) => {
      const delay = index * 400 + 100;

      setTimeout(() => {
        setLines((lines) =>
          lines.map((line, lineIndex) =>
            lineIndex === index ? { ...line, height: line.endHeight } : line
          )
        );
      }, delay);

      setTimeout(
        () =>
          setVisibleDivs((prev) => ({ ...prev, [`div${index + 1}`]: true })),
        delay + 400
      );
    });

    const lastLineDelay = filterededucation.length * 400 + 100;
    setTimeout(() => {
      setLines((lines) =>
        lines.map((line, index) =>
          index === filterededucation.length
            ? { ...line, height: line.endHeight }
            : line
        )
      );
    }, lastLineDelay);
  }, [filterededucation]);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        const newLines = calculateInitialLines(
          window.innerWidth <= 991,
          filterededucation.length,
          degreeTextRef.current
            ? degreeTextRef.current.clientHeight
            : descriptionAdjustment
        );
        // Directly set the lines with their endHeight
        const updatedLines = newLines.map((line) => ({
          ...line,
          height: line.endHeight,
        }));
        setLines(updatedLines);
      }, 100);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [filterededucation.length, descriptionAdjustment, handleAnimation]);

  useEffect(() => {
    setLines((lines) => lines.map((line) => ({ ...line, height: "0px" })));
    handleAnimation();
  }, [location.pathname, filterededucation, handleAnimation]);

  const handleOpenModal = (job) => {
    setSelectedJob(job);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSkillChange = (event) => {
    const { value, checked } = event.target;
    setSelectedSkills((prevSkills) =>
      checked
        ? [...prevSkills, value]
        : prevSkills.filter((skill) => skill !== value)
    );
  };

  const toggleFiltersModal = () => {
    setTimeout(() => {
      setShowFiltersModal((prevShowFilters) => !prevShowFilters);
    }, 500);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const resetFilters = () => {
    setSelectedSkills([]);
    setSearchTerm("");
  };

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };
  const degreeTextRef = useRef(null);

  useEffect(() => {
    if (degreeTextRef.current) {
      setDescriptionAdjustment(degreeTextRef.current.clientHeight);
    }
  }, [showMore]);

  useEffect(() => {
    const newLines = calculateInitialLines(
      window.innerWidth <= 991,
      filterededucation.length,
      descriptionAdjustment
    );
    setLines(newLines);
    handleAnimation();
  }, [filterededucation, descriptionAdjustment, handleAnimation]);

  return (
    <>
      <div id="gifeducation"></div>
      <Container
        fluid
        className="p-2 mt-5"
        style={{ backgroundColor: "transparent" }}
      >
        <Row className="mt-5">
          <Col xs={12} sm={12} md={12} lg={5} xl={4} xxl={3}>
            <ProfileCircle animate={isAnimating}>
              <img
                src="https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/2.jpg?updatedAt=1718246271947"
                alt="Profile pic"
                degree="Profile pic"
                style={{}}
              />
            </ProfileCircle>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={7}
            xl={8}
            xxl={9}
            ref={degreeTextRef}
          >
            {window.innerWidth <= 991 && (
              <h4 style={{ position: "relative ", zIndex: "10" }}>
                Higher Education
              </h4>
            )}
            <TitleText animate={isAnimating} showMore={showMore}>
              {window.innerWidth <= 991 ? (
                <span className="description">
                  My academic journey began with a Bachelor's degree in Physics
                  from Universidade do Porto, where I laid a solid foundation in
                  classical mechanics, electromagnetism, and quantum mechanics,
                  graduating with the third best grade out of 50 students. I then
                  pursued Engineering Physics with a focus on Nuclear Fusion
                  Science at the University of Stuttgart and Universite de
                  Lorraine, where I excelled in advanced plasma physics,
                  numerical analysis, and cutting-edge research. Transitioning
                  into software development, I completed a Bootcamp in FullStack
                  Development at EPICODE, followed by a Higher Diploma in
                  Science of Computing from Atlantic Technological University,
                  both with top honors. Most recently, I achieved an MSc in
                  Software Design with Cloud Native Computing from the
                  Technological University of the Shannon, where I mastered
                  microservices architecture, container deployment, and
                  full-stack development, culminating in a successful work
                  placement at Datto. This diverse academic background has
                  equipped me with a unique blend of physics expertise and
                  software development skills, driving my continued success in
                  both fields.
                </span>
              ) : (
                <>
                  <h4 style={{ position: "relative ", zIndex: "10" }}>
                    Higher Education
                  </h4>
                  <p className="description">
                    My academic journey began with a Bachelor's degree in
                    Physics from Universidade do Porto, where I laid a solid
                    foundation in classical mechanics, electromagnetism, and
                    quantum mechanics, graduating with the third best grade out of
                    50 students. I then pursued Engineering Physics with a focus
                    on Nuclear Fusion Science at the University of Stuttgart and
                    Universite de Lorraine, where I excelled in advanced plasma
                    physics, numerical analysis, and cutting-edge research.
                    Transitioning into software development, I completed a
                    Bootcamp in FullStack Development at EPICODE, followed by a
                    Higher Diploma in Science of Computing from Atlantic
                    Technological University, both with top honors. Most
                    recently, I achieved an MSc in Software Design with Cloud
                    Native Computing from the Technological University of the
                    Shannon, where I mastered microservices architecture,
                    container deployment, and full-stack development,
                    culminating in a successful work placement at Datto. This
                    diverse academic background has equipped me with a unique
                    blend of physics expertise and software development skills,
                    driving my continued success in both fields.
                  </p>
                </>
              )}
              <span
                className="toggle-button"
                onClick={toggleShowMore}
                style={{ fontSize: "0.5em" }}
              >
                {showMore ? "Show Less" : "View All"}
              </span>
            </TitleText>
          </Col>
        </Row>
        <Row
          className="mt-3"
          style={{ position: "absolute", zIndex: 10, width: "100%" }}
        >
          <Col xs={12} className="d-flex justify-content-start">
            {
              <>
                <Button
                  onClick={toggleFiltersModal}
                  className={`custom-button ${
                    isAnimating ? "button-exit-active" : "button-enter-active"
                  }`}
                >
                  {showFiltersModal ? "Hide Filters" : "Show Filters"}
                </Button>
                <Button
                  onClick={resetFilters}
                  className={`custom-button ${
                    isAnimating ? "button-exit-active" : "button-enter-active"
                  }`}
                >
                  Reset Filters
                </Button>
              </>
            }
          </Col>
        </Row>
        {lines.map((line, index) => (
          <VerticalLine
            key={index}
            left={line.left}
            top={line.top}
            height={line.height}
            animate={isAnimating}
          />
        ))}
        {filterededucation.map((job, index) => (
          <JobDiv
            key={index}
            left={
              window.innerWidth <= 991 ? "0%" : index % 2 === 0 ? "0%" : "25%"
            }
            marginTop={window.innerWidth <= 991 ? `${100}px` : `${100}px`}
            animate={isAnimating}
            visible={visibleDivs[`div${index + 1}`]}
            onClick={() => handleOpenModal(job)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexWrap: "wrap",
                textAlign: "center",
              }}
            >
              {job.duration} - {job.degree} at {job.institution}
            </div>
          </JobDiv>
        ))}
        {selectedJob && (
          <EducationDetailsModal
            showModal={showModal}
            handleClose={handleCloseModal}
            job={selectedJob}
          />
        )}
        <SkillFilterModal
          show={showFiltersModal}
          handleClose={toggleFiltersModal}
          selectedSkills={selectedSkills}
          handleSkillChange={handleSkillChange}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          uniqueSkills={uniqueSkills}
        />
      </Container>
    </>
  );
};

export default Education;
