import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./NavBar.css";

function NavBar({ startAnimation }) {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const navigate = useNavigate();
  const location = useLocation(); // This hook returns the current location object
  const [activePath, setActivePath] = useState(location.pathname); // Track the active path for styling

  useEffect(() => {
    setActivePath(location.pathname); // Update active path whenever the location changes
  }, [location]);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const handleNavLinkClick = (path) => {
    if (!isNavCollapsed) {
      setIsNavCollapsed(true);
    }
    startAnimation();
    setTimeout(() => {
      navigate(path);
    }, 500); // Navigate after animations
  };

  return (
    <div className="navbar">
      <img src="/logoGPR.png" alt="Logo" className="navbar-logo" />
      <button className="navbar-toggle" onClick={handleNavCollapse}>
        <img src="/logoGPR.png" alt="Menu" className="navbar-toggler-icon" />
        <div className="hamburger-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div className={`navbar-row ${isNavCollapsed ? "collapsed" : ""}`}>
        {[
          { path: "/home", label: "Home" },
          { path: "/experience", label: "Experience" },
          { path: "/education", label: "Education" },
          { path: "/projects", label: "Projects" },
          { path: "/contact", label: "Contact" }
        ].map((link) => (
          <div className={`navbar-item`}
               key={link.label} onClick={() => handleNavLinkClick(link.path)}>
            <span className={`navbar-text ${activePath === link.path ? "active" : ""}`} >{link.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NavBar;
