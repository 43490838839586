import React, { useMemo } from "react";
import styled from "styled-components";
import {
  Modal,
  Button as BootstrapButton,
  Form,
  Row,
  Col,
} from "react-bootstrap";

const CustomButton = styled(BootstrapButton)`
  border-color: white;
  color: white;
  &:hover {
    background-color: #ffffff;
    color: black;
    border-color: #ffffff;
  }
`;

const SkillFilterModal = ({
  show,
  handleClose,
  selectedSkills,
  handleSkillChange,
  searchTerm,
  handleSearchChange,
  uniqueSkills,
}) => {
  const filteredSkills = useMemo(() => {
    return uniqueSkills.filter((skill) =>
      skill.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, uniqueSkills]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      dialogClassName="skill-filter-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Filter Skills</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formSkills">
          <Form.Control
            type="text"
            placeholder="Search skills"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ fontSize: "0.9em" }}
          />
        </Form.Group>
        <Form.Group controlId="formSkills">
          <Row>
            {filteredSkills.map((skill, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={3}>
                <Form.Check
                  type="checkbox"
                  label={`${skill}`}
                  value={skill}
                  checked={selectedSkills.includes(skill)}
                  onChange={handleSkillChange}
                  style={{ fontSize: "0.9em" }}
                />
              </Col>
            ))}
          </Row>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton variant="Primary" onClick={handleClose}>
          Select Skills
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default SkillFilterModal;
