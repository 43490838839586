const projects = [
  {
    name: "Dropbox clone - Filebox",
    dateOfCompletion: "Aug 2023",
    description:
      "This Dropbox clone project features a backend built for Ubuntu servers using ZFS-based storage and a frontend developed with React. Key backend functionalities include ZFS pool management, sudoers configuration, and API endpoints for user authentication and file services. The frontend setup involves repository cloning, dependency installation, script execution, and environment configuration. This project demonstrates essential software development practices and encourages contributions following standard git workflows and React coding guidelines.",
    imageUrls: [
      "https://media.licdn.com/dms/image/D4D2DAQHV_jZnb5Yv-w/profile-treasury-image-shrink_800_800/0/1702227024512?e=1718578800&v=beta&t=1ce_vRgmi0feKwWFw0xqCISSyYhLl_LaWvY885TQ0lg",
      "https://media.licdn.com/dms/image/D4D2DAQHl0oXsgXamSw/profile-treasury-image-shrink_800_800/0/1702226999862?e=1718578800&v=beta&t=mGQ9mt4jE3AMk3NsXmWufQx-QXL8mGnY-2y3UmSTKV8",
      "https://media.licdn.com/dms/image/D4D2DAQGRYVpiDW7MHw/profile-treasury-image-shrink_800_800/0/1702226973478?e=1718578800&v=beta&t=hC-MEgRCBYEnyoBbQhpIGnW6-1zlR8tXCdpDTLEH1SQ",
      "https://media.licdn.com/dms/image/D4D2DAQEeVhZdOEY8yg/profile-treasury-image-shrink_800_800/0/1702226953582?e=1718578800&v=beta&t=-ErzHjULgPsluwmD24nt9T3uXiOwfJkde4gZ2hOQKkE",
      "https://media.licdn.com/dms/image/D4D2DAQGxdhZqxLLo9Q/profile-treasury-image-shrink_800_800/0/1702226935860?e=1718578800&v=beta&t=NetWP2CjKmRQseO-B4kHeCQJ-WLvwVl552kPliQsfaE",
    ],
    affiliations: ["Datto", "Technological University of the Shannon"],
    skills: [
      "PHP",
      "MySQL",
      "JavaScript",
      "Symfony",
      "React.js",
      "Ubuntu Server",
      "ZFS Storage",
      "ZFS Pool Management",
      "Sudoers Configuration",
      ".env Configuration",
      "API Development",
      "User Authentication",
      "File Services",
      "React Router",
      "Bootstrap",
      "Dependency Management",
      "Script Execution",
      "Development Server Setup",
      "Production Build Scripts",
      "Interactive Testing",
      "System Integration",
      "Git Workflows",
      "Environment Setup",
    ],
    links: [
      "https://github.com/Gustolandia/dropboxbackend",
      "https://github.com/Gustolandia/dropboxfrontend",
      "https://www.youtube.com/watch?v=u76Cas9RbGc",
    ],
    linksTitles: [
      "Backend Repository",
      "Frontend Repository",
      "Demo of Filebox",
    ],
  },
  {
    name: "Carsurfing Application",
    dateOfCompletion: "Apr 2023",
    description:
      "The Carsurfing App is a sophisticated carpooling application built with a modular design using Spring Boot and Maven, encompassing user, review, and ride services for enhanced maintainability and scalability. Key features include user authentication, ride booking, and review management, all facilitated by a user-friendly frontend developed in Vue.js by Gillian Middleton. The application leverages Docker and Docker Compose for efficient testing and deployment, integrates MySQL for database management, and uses Eureka Discovery Service for service registration. Developers can test the app locally using Docker, supporting various backend functionalities such as user and ride management, and review addition, all testable via HTTP clients or the integrated frontend.",
    imageUrls: [
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/Carsurfing_snip.png?updatedAt=1718064056940",
    ],
    affiliations: ["Technological University of the Shannon"],
    collaborators: ["Gillian Middleton", "Catalin Patularu"],
    skills: [
      "Java",
      "Spring Boot",
      "Spring Security",
      "Spring Data JPA",
      "MySQL",
      "Docker",
      "Docker Compose",
      "Maven",
      "Microservices Architecture",
      "Eureka Discovery Service",
      "RESTful API Development",
      "JWT Authentication",
      "Vue.js",
      "Vue Router",
      "Vuex",
      "Bootstrap Vue",
      "Responsive Design",
      "Continuous Integration/Continuous Deployment (CI/CD)",
      "Unit Testing",
      "Integration Testing",
      "HTTP Client Testing",
      "Database Management",
      "SQL Scripting",
      "Service Registration",
      "Service Discovery",
      "Environment Configuration",
      "User Authentication",
      "Ride Booking",
      "Review Management",
      "Frontend-Backend Integration",
      "Local Development Environment Setup",
      "Version Control (Git)",
      "Git Workflows",
      "Development Best Practices",
    ],
    links: [
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Article.pdf?updatedAt=1714593500595",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/ProjectPoster.pdf?updatedAt=1718103838326",
      "https://www.youtube.com/watch?v=De-ouqBPsrk",
    ],
    linksTitles: ["Article", "Project Poster", "Demo of Carsurfing"],
  },
  {
    name: "My YouTube Bookmarks",
    dateOfCompletion: "Jun 2022",
    description:
      "My YouTube Bookmarks is a Chrome extension designed to enhance your YouTube experience by allowing you to save and manage timestamps on your favorite videos. With this extension, you can easily create and name bookmarks at specific moments in YouTube videos. If you ever lose track of the videos you bookmarked, you can conveniently manage all your saved timestamps from the options page, ensuring you never lose the important parts of long videos again.",
    imageUrls: [
      "https://lh3.googleusercontent.com/uxG6eC413qiqi8IgkKkdojCKloLzaW-kZFqEFWZRoIMdvF0j4MH1OjQ4ijVp2lk6Sb86vZoHVjIJQ1cue_QzyB-e=s1280-w1280-h800",
      "https://lh3.googleusercontent.com/0pnNCE71ARa-9x1fpI-d4Bx5spZ79DYep7plVqlYtK0hvoE9UWjc_fgmilpmfTOU6V2Bs5nMFPWf42S_A0zFF68W=s1280-w1280-h800",
      "https://lh3.googleusercontent.com/0SjmWgpP_XB08haex4TYdaF03WlDiTZxLTWI6bvbcK3YVWjZBao5LctoROym666M1jAGG9_vqdhJ-it7sNZNe8HGlTc=s1280-w1280-h800",
    ],
    skills: [
      "JavaScript",
      "HTML",
      "CSS",
      "Chrome Extensions",
      "Manifest V3",
      "DOM Manipulation",
      "Event Handling",
      "Local Storage",
      "Asynchronous Programming",
      "User Interface Design",
      "Cross-Browser Compatibility",
      "Version Control (Git)",
      "Web Performance Optimization",
      "Accessibility",
    ],
    links: [
      "https://chromewebstore.google.com/detail/my-youtube-bookmarks/pchgdefjjeppamekboefmlhmkadjmegn",
    ],
    linksTitles: ["My YouTube Bookmarks Chrome Extension"],
  },
  {
    name: "LinkedIn Clone",
    dateOfCompletion: "Sep 2020",
    description:
      "The LinkedIn Clone App is a comprehensive full-stack application that emulates essential features of LinkedIn. The backend, developed using Express.js and MongoDB (hosted on Atlas), supports secure user authentication, profile management, experience tracking, and post creation. The frontend, built with React and Bootstrap, ensures a responsive and interactive user interface. Core functionalities include creating, updating, and viewing user profiles and experiences, as well as managing posts. The project emphasizes scalability and real-time data handling, providing a simplified yet functional version of the professional networking platform.",
    imageUrls: [
      "https://media.licdn.com/dms/image/D4D2DAQGoKcRh2XTUHg/profile-treasury-image-shrink_800_800/0/1702141481163?e=1718712000&v=beta&t=ogmgIcxCqKcJPuTDKCTvd7r_SY5vVdlY4rK9j3PdgmM",
      "https://media.licdn.com/dms/image/D4D2DAQHD7bvOPhWBbQ/profile-treasury-image-shrink_800_800/0/1702141512890?e=1718712000&v=beta&t=BFIPI02h4D5nOazHE9OTwFWfDrAtVpOUDn5cXvr5mhA",
    ],
    affiliations: ["EPICODE"],
    collaborators: ["Arbiona Shahu"],
    skills: [
      "Node.js",
      "Express.js",
      "MongoDB",
      "JavaScript",
      "React.js",
      "Bootstrap",
      "Atlas",
      "RESTful APIs",
      "JSON Web Tokens (JWT)",
      "Mongoose ODM",
      "Responsive Web Design",
      "Component-Based Architecture",
      "Version Control (Git)",
      "Asynchronous Programming",
      "Session Management",
      "Frontend Routing",
      "State Management",
      "Axios",
      "User Authentication",
      "CRUD Operations",
      "Data Modeling",
      "Form Handling",
      "Real-time Data Handling",
    ],
    links: [
      "https://github.com/Gustolandia/linkedin-backend",
      "https://gustavo-pedro-ricou-linkedin-frontend-default.glb.edgio.link/",
    ],
    linksTitles: [
      "GitHub - Gustolandia/linkedin-backend",
      "LinkedIn Clone Demo",
    ],
  },
  {
    name: "Ireland's Luxury Apartments",
    dateOfCompletion: "2022",
    description:
      "Ireland's Luxury Apartments is a comprehensive real estate platform designed to showcase luxurious apartment listings across Ireland. Built with a focus on user experience, the website features a detailed filtering system that allows users to search for apartments based on various criteria such as price, location, and amenities. Each listing includes high-quality images, detailed descriptions, pricing, and addresses. Administrators can log in to manage listings, update property details, and handle inquiries. The website supports all standard REST operations, ensuring efficient data handling and management.",
    imageUrls: [
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/Capture1.JPG?updatedAt=1718105311991",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/Capture2.JPG?updatedAt=1718105311997",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/Capture3.JPG?updatedAt=1718105311883",
    ],
    affiliations: ["Atlantic Technological University"],
    skills: [
      "HTML",
      "CSS",
      "PHP",
      "MariaDB",
      "JavaScript",
      "Bootstrap",
      "Responsive Web Design",
      "RESTful APIs",
      "CRUD Operations",
      "SQL",
      "Database Management",
      "User Authentication",
      "Session Management",
      "Form Validation",
      "Image Handling",
      "Filtering and Sorting",
      "Version Control (Git)",
      "Web Hosting (Awardspace)",
    ],
    links: ["http://irelandrealstate.onlinewebshop.net/"],
    linksTitles: ["Ireland's Luxury Apartments Preview"],
  },
  {
    name: "USA Manslaughter Analysis 1980-2014",
    dateOfCompletion: "2022",
    description:
      "This comprehensive analysis delves into the trends and patterns of manslaughter cases in the USA from 1980 to 2014. Utilizing advanced data analytics and visualization techniques, the project provides insights into various demographic factors and temporal trends. Key features include detailed statistical analysis, interactive visualizations, and an in-depth examination of the data to uncover significant findings.",
    imageUrls: [
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/Murder_Ethnicity.png?updatedAt=1718109280212",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/Murder%20by%20Month.png?updatedAt=1718109280183",
    ],
    affiliations: ["Technological University of the Shannon"],
    skills: [
      "Python",
      "Pandas",
      "Matplotlib",
      "Seaborn",
      "Jupyter Notebook",
      "SQL",
      "Data Analysis",
      "Statistical Modeling",
      "Data Visualization",
      "Machine Learning",
      "Predictive Analytics",
      "Hypothesis Testing",
      "Big Data",
      "Data Cleaning",
      "Exploratory Data Analysis",
    ],
    links: [
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/asl_2022_23_assignment_part2_A00304507.pdf?updatedAt=1714593497971",
    ],
    linksTitles: ["USA Manslaughter Analysis 1980-2014"],
  },
  {
    name: "Product Owner Role in Scrum",
    dateOfCompletion: "2021",
    description:
      "This comprehensive analysis delves into the essential role of the Product Owner in a Scrum development team. The Product Owner is pivotal in maximizing product value by managing the product backlog and ensuring the team works on the most crucial tasks. This role involves significant responsibilities, including defining and prioritizing user stories, collaborating with the Scrum Master and team members, and maintaining the product vision throughout the Scrum process. The analysis covers the fundamentals of Agile methodologies, the intricacies of the Scrum framework, and the critical responsibilities and skills required for a Product Owner to succeed.",
    imageUrls: [
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/Agile_Manifesto.png?updatedAt=1718109792972",
    ],
    affiliations: ["Atlantic Technological University"],
    skills: [
      "Agile (Scrum)",
      "Product Backlog Management",
      "User Story Writing",
      "Sprint Planning",
      "Stakeholder Engagement",
      "Prioritization",
      "JIRA",
      "Confluence",
      "Trello",
      "Team Collaboration",
      "Project Management",
      "Requirements Gathering",
      "Vision Setting",
    ],
    links: [
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/ProductOwnerEssentialRoleL00170136.pdf?updatedAt=1714593503089",
    ],
    linksTitles: [
      "The Product Owner plays an essential role in a Scrum development team",
    ],
  },
  {
    name: "Report on Reflectometry",
    dateOfCompletion: "2015",
    description:
      "This detailed report on Reflectometry, presented at the Winter Event at CEA Cadarache, delves into the physical principles, relevance for fusion research, and experimental procedures involved in reflectometry. The project, supervised by Dr. Frederic Clairet, utilized MatLab for extensive data analysis and visualization. Reflectometry, a radar technique used to measure plasma density and turbulence in fusion reactors, is crucial for optimizing plasma confinement and stability. The report includes theoretical explanations, device schematics, and data analysis from simulated experiments due to the unavailability of plasma in Tore Supra.",
    imageUrls: [
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/Schematic_Reflectometer.png?updatedAt=1718110426811",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/photo_reflect_CEA.jpg",
    ],
    affiliations: ["CEA Cadarache"],
    collaborators: ["Alejandro Muñoz"],
    skills: [
      "MatLab",
      "Signal Processing",
      "Radar Technology",
      "Data Analysis",
      "Plasma Physics",
      "Experimental Physics",
      "Scientific Research",
      "Numerical Methods",
      "Fourier Transform",
      "Fast Fourier Transform",
      "Abel Transform",
      "Phase Shift Analysis",
      "Density Profile Reconstruction",
      "Reflectometry",
    ],
    links: [
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Reflectometry.pdf?updatedAt=1714593492426",
    ],
    linksTitles: ["Report on Reflectometry"],
  },
  {
    name: "Disruption Studies on the COMPASS Tokamak",
    dateOfCompletion: "2014",
    description:
      "This comprehensive study on disruptions in the COMPASS tokamak was conducted for the EMTRAIC 2014 conference. The project, led by Gustavo Pedro Ricou under the supervision of J. Havlicek, O. Hronova, and A. Dvornova, aimed to investigate limit density disruptions in L-mode. Despite not achieving a density exceeding 40% of the Greenwald Limit, valuable insights were gained into the behavior of magnetic islands and the asymmetry of disruptions using saddle coils. Technologies utilized in this study included Python for data analysis and visualization. The research focused on understanding the dynamics of magnetic islands, the impact of MHD instabilities, and the diagnostic capabilities of the COMPASS tokamak. The findings contribute to the broader understanding of plasma disruptions and their mitigation in fusion reactors.",
    imageUrls: [
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/Disruptions.png?updatedAt=1718114639732g",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Compass_Tokamak.jpg?updatedAt=1718114690268",
    ],
    affiliations: [
      "Institute of Plasma Physics of the Czech Academy of Sciences",
    ],
    skills: [
      "Python",
      "NumPy",
      "SciPy",
      "Data Analysis",
      "Plasma Physics",
      "Magnetohydrodynamics (MHD)",
      "Signal Processing",
      "Tokamak Operation",
      "Scientific Research",
      "Greenwald Limit Analysis",
      "Magnetic Diagnostics",
      "Experimental Physics",
    ],
    links: [
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/reportPragueDisruptions.pdf?updatedAt=1714593495625",
    ],
    linksTitles: ["Disruption Studies on the COMPASS Tokamak"],
  },
  {
    name: "Automatic Data Control and Acquisition for the Study of Electrical Susceptibility and Thermally Stimulated Currents in Ceramics and Polarizable Films",
    dateOfCompletion: "2011",
    description: "This project involved the development of a comprehensive system for automatic data control and acquisition using NI LabVIEW. The focus was on studying electrical susceptibility and thermally stimulated currents in ceramics and polarizable films. The system integrated various instruments for precise measurements and data logging, significantly enhancing experimental efficiency and accuracy.",
    imageUrls: [
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/LabView2.png?updatedAt=1718115470572",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/LabView.png?updatedAt=1718115470441"
    ],
    affiliations: [
      "Institute of Physics for Advanced Materials, Nanotechnology and Photonics of the University of Porto"
    ],
    collaborators: ["Diogo P. Lopes", "Pedro I. T. K. Sarmento"],
    skills: [
      "NI LabVIEW",
      "G",
      "Data Acquisition",
      "Electrical Susceptibility",
      "Thermally Stimulated Currents",
      "Ceramics",
      "Polarizable Films",
      "Impedance Analysis",
      "Signal Processing",
      "High-Precision Measurement",
      "Automated Control Systems"
    ],
    links: ["https://ik.imagekit.io/Gustolandia/Portfolio%20Website/IFIMUP.pdf?updatedAt=1714593469018"],
    linksTitles: [
      "Automatic Data Control and Acquisition for the Study of Electrical Susceptibility and Thermally Stimulated Currents in Ceramics and Polarizable Films"
    ]
  }
  ,
];

export default projects;
