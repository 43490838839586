import React, { useState, useEffect, useMemo } from "react";
import SkillFilterModal from "./SkillFilterModal";
import styled, { css } from "styled-components";
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  Carousel,
  ButtonGroup,
} from "react-bootstrap";
import "./home.css";

const slideInFromLeft = css`
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  animation: slideInFromLeft 0.4s ease-out forwards;
`;

const slideOutToLeft = css`
  @keyframes slideOutToLeft {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(-100%);
      opacity: 0;
    }
  }

  animation: slideOutToLeft 0.4s ease-out forwards;
`;

const slideInFromRight = css`
  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  animation: slideInFromRight 0.4s ease-out forwards;
`;

const slideOutToRight = css`
  @keyframes slideOutToRight {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  animation: slideOutToRight 0.4s ease-out forwards;
`;

const animationTopEnterStyles = css`
  animation: slideInFromTop 0.5s ease-out forwards;
`;

const animationTopExitStyles = css`
  animation: slideOutToTop 0.5s ease-out forwards;
`;

const ProfileCircle = styled.div`
  width: 300px;
  height: 300px;
  background-color: #ddd;
  border-radius: 50%;
  margin-left: 100px;
  margin-top: 0px;
  ${(props) =>
    props.animate ? animationTopExitStyles : animationTopEnterStyles}
  position:relative;
  z-index: 1;
  overflow: hidden; /* Ensure overflow is hidden to clip the image */

  & > img {
    width: 100%; /* Ensure image covers the container */
    height: 170%;
    object-fit: cover;
    position: absolute;
  }
  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 200px;
  }
`;

const TitleText = styled.div`
  margin-left: 60px;
  color: white;
  ${(props) =>
    props.animate ? animationTopExitStyles : animationTopEnterStyles}
  position: relative;
  z-index: 1;
  .description {
    margin-bottom: 0px;
    width: 100%;
    font-size: 15px;
    display: ${(props) => (props.showMore ? "" : "-webkit-box")};
    -webkit-line-clamp: ${(props) => (props.showMore ? "unset" : 3)};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 991px) {
      width: 70%;
      font-size: 15px;
      white-space: ${(props) => (props.showMore ? "normal" : "nowrap")};
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media (max-width: 991px) {
    margin-left: 0px;
    display: ${(props) => (props.showMore ? "block" : "flex")};
  }
  .toggle-button {
    font-weight: bold;
    cursor: pointer;
    color: white;
    display: inline-block;
    margin-left: 5px;
    vertical-align: bottom;
    &:hover {
      filter: brightness(2);
    }
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  z-index: 10;
  .btn {
    border: 1px solid white;
    color: white;
    background-color: transparent;

    &:hover,
    &.active {
      background-color: white;
      color: black;
    }
  }

  ${(props) => (props.animate ? slideOutToLeft : slideInFromLeft)}
`;

const StyledButton = styled(Button)`
  border: 1px solid white;
  color: white;
  background-color: transparent;
  position: relative;
  top: 22px;
  &:hover {
    background-color: white;
    color: black;
  }

  ${(props) => (props.animate ? slideOutToRight : slideInFromRight)}
`;

const Description = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.showMore ? "unset" : 3)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
`;

const StyledSelect = styled.select`
  top: 4px;
  cursor: pointer;
  border: 1px solid white;
  color: white;
  background-color: transparent;
  padding: 5px;
  margin-left: 10px;
  height: 45px; /* Match the height of the buttons */
  border-radius: 5%; /* Rounded buttons */
  position: relative;
  z-index: 10;

  &:hover,
  &:focus {
    background-color: white;
    color: black;
  }

  ${(props) => (props.animate ? slideOutToRight : slideInFromRight)}
`;

const ProjectCard = styled(Card)`
  ${(props) =>
    props.animate
      ? props.index % 2 === 0
        ? slideOutToLeft
        : slideOutToRight
      : props.index % 2 === 0
      ? slideInFromLeft
      : slideInFromRight}
  height: ${(props) =>
    props.showMore ? "auto" : "600px"}; /* Adjust card height */
  overflow: hidden;
  position: relative;
  display:;

  &:after {
    content: "";
    display: ${(props) => (props.showMore ? "none" : "block")};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
  background: 
      linear-gradient(to top, rgba(169, 169, 169, 0.9), rgba(169, 169, 169, 0.7)),
      linear-gradient(to top, rgba(169, 169, 169, 0.7), rgba(169, 169, 169, 0.5)),
      linear-gradient(to top, rgba(169, 169, 169, 0.5), rgba(169, 169, 169, 0.3)),
      linear-gradient(to top, rgba(169, 169, 169, 0.3), rgba(169, 169, 169, 0.1)),
      linear-gradient(to top, rgba(169, 169, 169, 0.1), rgba(169, 169, 169, 0));
    );
    
  }
`;

const ProjectListItem = styled(ListGroup.Item)`
  ${(props) =>
    props.animate
      ? props.index % 2 === 0
        ? slideOutToLeft
        : slideOutToRight
      : props.index % 2 === 0
      ? slideInFromLeft
      : slideInFromRight}
`;

const StyledLabel = styled.label`
  ${(props) => (props.animate ? slideOutToRight : slideInFromRight)}
  margin-bottom: 5px;
  margin-left: 10px;
`;

const Projects = ({ isAnimating, projects = {} }) => {
  const [showMore, setShowMore] = useState({ title: false });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isGridView, setIsGridView] = useState(true); // State to toggle view
  const [sortOrder, setSortOrder] = useState("default"); // State for sorting

  const [showSkillModal, setShowSkillModal] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const uniqueSkills = useMemo(() => {
    const skills = projects.flatMap((project) => project.skills);
    return [...new Set(skills)];
  }, [projects]);

  const handleSkillChange = (e) => {
    const skill = e.target.value;
    setSelectedSkills((prevSelected) =>
      prevSelected.includes(skill)
        ? prevSelected.filter((s) => s !== skill)
        : [...prevSelected, skill]
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOpenSkillModal = () => setShowSkillModal(true);
  const handleCloseSkillModal = () => setShowSkillModal(false);
  const handleResetFilters = () => {
    setSelectedSkills([]);
    setSearchTerm("");
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const sortProjects = (projects) => {
    switch (sortOrder) {
      case "chronological":
        return [...projects].sort(
          (a, b) => new Date(a.dateOfCompletion) - new Date(b.dateOfCompletion)
        );
      case "reverseChronological":
        return [...projects].sort(
          (a, b) => new Date(b.dateOfCompletion) - new Date(a.dateOfCompletion)
        );
      case "alphabetical":
        return [...projects].sort((a, b) => a.name.localeCompare(b.name));
      case "reverseAlphabetical":
        return [...projects].sort((a, b) => b.name.localeCompare(a.name));
      default:
        return projects; // Default order (chronological)
    }
  };

  const toggleShowMore = (key) => {
    setShowMore((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth < 914) {
        setIsGridView(true); // Automatically switch to grid view on smaller screens
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatCollaborators = (collaborators) => {
    if (collaborators.length === 1) return collaborators[0];
    if (collaborators.length === 2) return collaborators.join(" and ");
    return `${collaborators.slice(0, -1).join(", ")}, and ${collaborators.slice(
      -1
    )}`;
  };

  const formatAffiliations = (affiliations) => {
    if (affiliations.length === 1) return affiliations[0];
    if (affiliations.length === 2) return affiliations.join(" and ");
    return `${affiliations.slice(0, -1).join(", ")}, and ${affiliations.slice(
      -1
    )}`;
  };

  const renderImages = (imageUrls) => {
    return (
      <Carousel>
        {imageUrls.map((url, index) => (
          <Carousel.Item key={index} style={{ height: "300px" }}>
            <img
              className="d-block w-100 h-100"
              src={url}
              alt={`Service down ${index + 1}`}
              style={{ objectFit: "cover", height: "300px" }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  const sortedProjects = sortProjects(projects);

  const filteredProjects = useMemo(() => {
    if (selectedSkills.length === 0) return sortedProjects;
    return sortedProjects.filter((project) =>
      project.skills.some((skill) => selectedSkills.includes(skill))
    );
  }, [sortedProjects, selectedSkills]);

  // Replace `sortedProjects` with `filteredProjects` in the renderProjectsGrid and renderProjectsList functions

  const renderProjectsGrid = () => {
    return (
      <Row>
        {filteredProjects.map((project, index) => (
          <Col
            key={index}
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            xxl={3}
            className="mb-4"
          >
            <ProjectCard
              animate={isAnimating}
              index={index}
              showMore={showMore[index]}
              style={{
                backgroundColor: "#A9A9A9",
                color: "white",
                display: "block",
              }}
            >
              {project.imageUrls.length > 1 ? (
                renderImages(project.imageUrls)
              ) : (
                <Card.Img
                  variant="top"
                  style={{
                    height: "300px",
                    objectFit: "cover",
                    display: "block",
                  }}
                  src={project.imageUrls[0]}
                />
              )}
              <Card.Body>
                <Card.Title>{project.name}</Card.Title>
                <div style={{ fontSize: "0.8em", color: "lightgray" }}>
                  {project.collaborators &&
                    project.collaborators !== "None" && (
                      <span>
                        with {formatCollaborators(project.collaborators)},
                      </span>
                    )}
                  {project.affiliations && project.affiliations !== "None" && (
                    <span> at {formatAffiliations(project.affiliations)},</span>
                  )}
                  {project.dateOfCompletion &&
                    project.dateOfCompletion !== "None" && (
                      <span> completed {project.dateOfCompletion}</span>
                    )}
                </div>
                <div style={{ fontSize: "0.8em", color: "lightgray" }}>
                  {" "}
                  {/* Smaller font and light gray color */}
                  {project.skills.map((skill, i) => (
                    <span key={i} style={{ marginRight: "5px" }}>
                      #{skill}
                    </span>
                  ))}
                </div>
                <Description showMore={showMore[index]}>
                  {project.description}
                </Description>
                <div>
                  <ul style={{ fontSize: "0.8em" }}>
                    {" "}
                    {/* Smaller font */}
                    {project.links.map((link, i) => (
                      <li key={i}>
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {project.linksTitles[i]}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                {project.description.length > 200 && (
                  <Button
                    variant="link"
                    className="show-more-button"
                    onClick={() => toggleShowMore(index)}
                    style={{
                      width: "100%",
                      backgroundColor: "transparent",
                      border: "none",
                      color: "white",
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      textAlign: "center",
                      padding: "10px 0",
                      zIndex: "10",
                      textDecoration: "none", // Remove underline
                    }}
                  >
                    {showMore[index] ? "Show Less" : "Show More"}
                  </Button>
                )}
              </Card.Body>
            </ProjectCard>
          </Col>
        ))}
      </Row>
    );
  };

  const getWidth = () => {
    if (windowWidth < 576) return "100%"; // xs
    if (windowWidth < 768) return "350px"; // sm
    if (windowWidth < 992) return "400px"; // md
    if (windowWidth < 1200) return "450px"; // lg
    return "500px"; // xl and above
  };

  const renderProjectsList = () => {
    return (
      <ListGroup>
        {filteredProjects.map((project, index) => (
          <ProjectListItem
            key={index}
            animate={isAnimating}
            index={index}
            className="d-flex mb-5"
            style={{ backgroundColor: "#A9A9A9", color: "white" }}
          >
            <div className="mr-3" style={{ width: getWidth() }}>
              {project.imageUrls.length > 1 ? (
                <Carousel style={{ width: getWidth() }}>
                  {project.imageUrls.map((url, i) => (
                    <Carousel.Item key={i}>
                      <img
                        src={url}
                        alt={`Project ${project.name} ${i}`}
                        style={{
                          width: "100%",
                          height: "400px",
                          objectFit: "cover",
                        }} // Ensure the carousel images fit the container
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <img
                  src={project.imageUrls[0]}
                  alt={`Project ${index}`}
                  style={{
                    width: getWidth(),
                    height: "400px",
                    objectFit: "cover",
                  }} // Ensure the image fits the container
                />
              )}
            </div>
            <div style={{ marginLeft: "20px" }}>
              {" "}
              {/* Apply margin left here */}
              <h5>{project.name}</h5>
              <div style={{ fontSize: "0.8em", color: "lightgray" }}>
                {project.collaborators && project.collaborators !== "None" && (
                  <span>
                    with {formatCollaborators(project.collaborators)},
                  </span>
                )}
                {project.affiliations && project.affiliations !== "None" && (
                  <span> at {formatAffiliations(project.affiliations)},</span>
                )}
                {project.dateOfCompletion &&
                  project.dateOfCompletion !== "None" && (
                    <span> completed {project.dateOfCompletion}</span>
                  )}
              </div>
              <div style={{ fontSize: "0.8em", color: "lightgray" }}>
                {" "}
                {/* Smaller font and light gray color */}
                {project.skills.map((skill, i) => (
                  <span key={i} style={{ marginRight: "5px" }}>
                    #{skill}
                  </span>
                ))}
              </div>
              <Description showMore={showMore[index]}>
                {project.description}
              </Description>
              <Button
                variant="link"
                className="toggle-button"
                style={{ fontSize: "0.5em" }}
                onClick={() => toggleShowMore(index)}
              >
                {showMore[index] ? "Show Less" : "Show more"}
              </Button>
              <div>
                <ul style={{ fontSize: "0.8em" }}>
                  {" "}
                  {/* Smaller font */}
                  {project.links.map((link, i) => (
                    <li key={i}>
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        {project.linksTitles[i]}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </ProjectListItem>
        ))}
      </ListGroup>
    );
  };

  return (
    <div className="background">
      <div id="gifprojects"></div>
      <Container
        fluid
        className="p-2 mt-5"
        style={{ backgroundColor: "transparent" }}
      >
        <Row className="mt-5">
          <Col xs={12} sm={12} md={12} lg={5} xl={4} xxl={3}>
            <ProfileCircle animate={isAnimating}>
              <img
                src="https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/348941758_747924850451072_8667307110220728782_n.jpg?updatedAt=1718246272102"
                alt="Profile pic"
                title="Profile pic"
                style={{top:"-70px"}}
              />
            </ProfileCircle>
          </Col>
          <Col xs={12} sm={12} md={12} lg={7} xl={8} xxl={9}>
            {window.innerWidth <= 991 && (
              <h4 style={{ position: "relative ", zIndex: "10" }}>Projects</h4>
            )}
            <TitleText animate={isAnimating} showMore={showMore.title}>
              {windowWidth <= 991 ? (
                <span className="description">
                  Explore my projects that span across various domains and
                  technologies. My portfolio includes a range of innovative
                  applications and comprehensive studies, demonstrating my
                  proficiency in both front-end and back-end development, as
                  well as data analysis and scientific research. Key areas
                  covered include cloud storage solutions, microservices
                  architecture, real-time data handling, user authentication,
                  and responsive web design using technologies such as React,
                  Spring Boot, Vue.js, Node.js, and PHP. Additionally, I have
                  experience in advanced data analytics, visualization
                  techniques, and the implementation of Agile methodologies in
                  software development. These projects highlight my ability to
                  integrate various tools and frameworks, manage complex
                  systems, and deliver high-quality software solutions.
                </span>
              ) : (
                <>
                  <h4 style={{ position: "relative ", zIndex: "10" }}>
                    Projects
                  </h4>
                  <p className="description">
                    Explore my projects that span across various domains and
                    technologies. My portfolio includes a range of innovative
                    applications and comprehensive studies, demonstrating my
                    proficiency in both front-end and back-end development, as
                    well as data analysis and scientific research. Key areas
                    covered include cloud storage solutions, microservices
                    architecture, real-time data handling, user authentication,
                    and responsive web design using technologies such as React,
                    Spring Boot, Vue.js, Node.js, and PHP. Additionally, I have
                    experience in advanced data analytics, visualization
                    techniques, and the implementation of Agile methodologies in
                    software development. These projects highlight my ability to
                    integrate various tools and frameworks, manage complex
                    systems, and deliver high-quality software solutions.
                  </p>
                </>
              )}
              <span
                className="toggle-button"
                onClick={() => toggleShowMore("title")}
                style={{ fontSize: "0.5em" }}
              >
                {showMore.title ? "Show Less" : "View All"}
              </span>
            </TitleText>
          </Col>
        </Row>
        <Row className="mb-3 mt-5">
          <Col className="d-flex justify-content-between align-items-center">
            <>
              {windowWidth >= 914 ? (
                <StyledButtonGroup animate={isAnimating}>
                  <Button
                    variant={isGridView ? "primary" : "secondary"}
                    className={isGridView ? "active" : ""}
                    onClick={() => setIsGridView(true)}
                  >
                    Grid View
                  </Button>
                  <Button
                    variant={!isGridView ? "primary" : "secondary"}
                    className={!isGridView ? "active" : ""}
                    onClick={() => setIsGridView(false)}
                  >
                    List View
                  </Button>
                </StyledButtonGroup>
              ) : (
                <div></div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                  top: "-20px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StyledButton
                    onClick={handleOpenSkillModal}
                    style={{ marginRight: "10px", zIndex: 10 }}
                    animate={isAnimating}
                  >
                    Filter
                  </StyledButton>
                  <StyledButton
                    onClick={handleResetFilters}
                    style={{ marginRight: "10px", zIndex: 10 }}
                    animate={isAnimating}
                  >
                    Reset
                  </StyledButton>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <StyledLabel animate={isAnimating} htmlFor="sortOrder">
                      Sort by:
                    </StyledLabel>
                    <StyledSelect
                      id="sortOrder"
                      value={sortOrder}
                      onChange={handleSortChange}
                      animate={isAnimating}
                    >
                      <option value="reverseChronological">
                        Newest first...
                      </option>
                      <option value="chronological">Oldest first...</option>
                      <option value="alphabetical">A to Z</option>
                      <option value="reverseAlphabetical">Z to A</option>
                    </StyledSelect>
                  </div>
                </div>
              </div>
              <SkillFilterModal
                show={showSkillModal}
                handleClose={handleCloseSkillModal}
                selectedSkills={selectedSkills}
                handleSkillChange={handleSkillChange}
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                uniqueSkills={uniqueSkills}
              />
            </>
          </Col>
        </Row>

        {isGridView ? renderProjectsGrid() : renderProjectsList()}
      </Container>
    </div>
  );
};

export default Projects;
