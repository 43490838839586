import React, { useState, useEffect } from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa";

import styled, { css } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import "./home.css";
import "./contact.css";

const animationTopEnterStyles = css`
  animation: slideInFromTop 0.5s ease-out forwards;
`;

const animationTopExitStyles = css`
  animation: slideOutToTop 0.5s ease-out forwards;
`;

const ProfileCircle = styled.div`
  width: 300px;
  height: 300px;
  background-color: #ddd;
  border-radius: 50%;
  margin-left: 100px;
  margin-top: 0px;
  ${(props) =>
    props.animate ? animationTopExitStyles : animationTopEnterStyles}
  position:relative;
  z-index: 1;
  overflow: hidden; /* Ensure overflow is hidden to clip the image */

  & > img {
    width: 100%; /* Ensure image covers the container */
    height: 170%;
    object-fit: cover;
    position: absolute;
  }
  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 200px;
  }
`;

const TitleText = styled.div`
  margin-left: 60px;
  color: white;
  ${(props) =>
    props.animate ? animationTopExitStyles : animationTopEnterStyles}
  position: relative;
  z-index: 1;
  & > .description {
    margin-bottom: 0px;
    width: 100%;
    font-size: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 991px) {
      width: auto;
      font-size: 15px;
      white-space: nowrap; /* Make text in a single line */
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline;
    }
  }
  @media (max-width: 991px) {
    margin-left: 0px;
    display: flex;
    justify-content: space-between; /* Align the description and icons on opposite sides */
    align-items: center;
  }
  .toggle-button {
    font-weight: bold;
    cursor: pointer;
    color: white;
    display: inline-block;
    margin-left: 5px;
    vertical-align: bottom; /* Align button properly */
  }
  .icons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    @media (max-width: 991px) {
      margin-left: 10px;
    }
  }
  .icon {
    cursor: pointer;
    color: white;
    &:hover {
      color: rgb(108, 108, 243) !important;
    }
  }
`;


const FatherDiv = styled.div`
  animation: ${(props) =>
    props.animate
      ? css`slideOutToRight 0.4s ease-out forwards`
      : css`slideInFromLeft 0.4s ease-out forwards`};
`;

const Contact = ({ isAnimating }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [formData, setFormData] = useState({
    subject: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState({
    sending: false,
    messageSent: false,
    error: null,
  });
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!formData.subject) errors.subject = "Subject is required";
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.message) errors.message = "Message is required";
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setStatus({ sending: true, messageSent: false, error: null });

    const params = new URLSearchParams({
      access_token: process.env.REACT_APP_ACCESS_TOKEN,
      subject: formData.subject,
      text: `From: ${formData.email}. Message: ${formData.message}`,
    });

    try {
      const response = await fetch("https://postmail.invotes.com/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: params.toString(),
      });

      if (response.ok) {
        setStatus({ sending: false, messageSent: true, error: null });
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      setStatus({ sending: false, messageSent: false, error: error.message });
    }
  };

  const resetForm = () => {
    setFormData({ subject: "", email: "", message: "" });
    setStatus({ sending: false, messageSent: false, error: null });
    setFormErrors({});
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="background">
      <div id="gifcontact"></div>
      <Container
        fluid
        className="p-2 mt-5"
        style={{ backgroundColor: "transparent" }}
      >
        <Row className="mt-5">
          <Col xs={12} sm={12} md={12} lg={5} xl={4} xxl={3}>
            <ProfileCircle animate={isAnimating}>
              <img
                src="https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/275422095_7871262119566558_1330687517521712161_n%20(1).jpg?updatedAt=1718246272074"
                alt="Profile pic"
                title="Profile pic"
              />
            </ProfileCircle>
          </Col>
          <Col xs={12} sm={12} md={12} lg={7} xl={8} xxl={9}>
            {window.innerWidth <= 991 && (
              <h4 style={{ position: "relative ", zIndex: "10" }}>Contact</h4>
            )}
            <TitleText animate={isAnimating}>
              {windowWidth <= 991 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width:"100%",
                  }}
                >
                  <span className="description">
                    Feel free to contact me here or on LinkedIn.
                  </span>
                  <div className="icons">
                    <FaLinkedin
                      className="icon"
                      size={40}
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/in/gricou",
                          "_blank"
                        )
                      }
                    />
                    <FaGithub
                      className="icon"
                      size={40}
                      onClick={() =>
                        window.open("https://github.com/Gustolandia", "_blank")
                      }
                    />
                  </div>
                </div>
              ) : (
                <>
                  <h4 style={{ position: "relative ", zIndex: "10" }}>
                    Contact
                  </h4>
                  <p className="description">
                    Feel free to contact me here or on LinkedIn.
                  </p>
                  <div className="icons">
                    <FaLinkedin
                      className="icon"
                      size={80}
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/in/gricou",
                          "_blank"
                        )
                      }
                    />
                    <FaGithub
                      className="icon"
                      size={80}
                      onClick={() =>
                        window.open("https://github.com/Gustolandia", "_blank")
                      }
                    />
                  </div>
                </>
              )}
            </TitleText>
          </Col>
        </Row>
        <div className="mt-5">
          <FatherDiv className="father" animate={isAnimating}>
            {status.messageSent ? (
              <div className="success-message">
                <div className="check-mark">✓</div>
                <p>Message sent!</p>
                <button onClick={resetForm}>Send another message</button>
              </div>
            ) : (
              <div className="formDiv">
                {status.error && (
                  <p className="error-message">{status.error}</p>
                )}
                <form id="send_form" onSubmit={handleSubmit}>
                  <label htmlFor="subject">Subject:</label>
                  {formErrors.subject && (
                    <p className="error-message">{formErrors.subject}</p>
                  )}
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    id="subject"
                    value={formData.subject}
                    onChange={handleChange}
                  />

                  <label htmlFor="email">Your contact email:</label>
                  {formErrors.email && (
                    <p className="error-message">{formErrors.email}</p>
                  )}
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                  />

                  <label htmlFor="message">Message:</label>
                  {formErrors.message && (
                    <p className="error-message">{formErrors.message}</p>
                  )}
                  <textarea
                    name="message"
                    placeholder="Message"
                    id="message"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>

                  <input
                    type="submit"
                    value={status.sending ? "Sending…" : "Send"}
                    disabled={status.sending}
                    id="js_send"
                  />
                </form>
              </div>
            )}
          </FatherDiv>
        </div>
      </Container>
    </div>
  );
};

export default Contact;
