import styled, { css } from "styled-components";

export const VerticalLine = styled.div`
  position: absolute;
  left: ${({ left }) => left}; // Destructure props
  top: ${({ top }) => top};
  width: 50px;
  background: ${({ animate }) =>
    animate
      ? "transparent"
      : "linear-gradient(to bottom, black 0%, white 100%)"};
  height: ${({ height }) => height};
  transition: all 0.4s ease;
  transform: translateX(-50%);

  @media (max-width: 991px) {
    left: 50%;
`;

export const JobDiv = styled.div`
  position: relative;
  left: ${({ left }) => left};
  margin-top: ${({ marginTop }) => marginTop};
  width: 75%;
  height: 150px;
  padding: 20px;
  background: ${({ animate }) =>
    animate
      ? "transparent"
      : "linear-gradient(to bottom, white 0%, black 100%)"};
  color: ${({ animate }) => (animate ? "transparent" : "white")};
  border-radius: 5px;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: all 0.4s ease;
  cursor: pointer;
  &:hover {
    color: ${({ animate }) => (animate ? "transparent" : "rgb(108, 108, 243)")};
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
  @media (max-width: 991px) {
    left: 5%;
    width: 90%;
  }
`;

export const ProfileCircle = styled.div`
  width: 300px;
  height: 300px;
  background-color: #ddd;
  border-radius: 50%;
  margin-left: 100px;
  margin-top: 0px;
  ${(props) =>
    props.animate ? animationTopExitStyles : animationTopEnterStyles}
  position:relative;
  z-index: 1;
  overflow: hidden; /* Ensure overflow is hidden to clip the image */

  & > img {
    width: 100%; /* Ensure image covers the container */
    height: 170%;
    object-fit: cover;
    position: absolute;
  }
  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 200px;
  }
`;

export const TitleText = styled.div`
  margin-left: 60px;
  color: white;
  ${(props) =>
    props.animate ? animationTopExitStyles : animationTopEnterStyles}
  position: relative;
  z-index: 1;
  & > .description {
    margin-bottom: 0px;
    width: 100%;
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (props.showMore ? "unset" : 3)};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 991px) {
      width: 70%;
      font-size: 15px;
      white-space: ${(props) =>
        props.showMore ? "" : "nowrap"}; /* Make text in a single line */
      overflow: hidden;
      text-overflow: ellipsis;
      display: ${(props) =>
        props.showMore ? "inline" : "block"}; /* Toggle display */
    }
  }
  @media (max-width: 991px) {
    margin-left: 0px;
    display: ${(props) => (props.showMore ? "" : "flex")};
  }
  .toggle-button {
    font-weight: bold;
    cursor: pointer;
    color: white;
    display: inline-block;
    margin-left: 5px;
    vertical-align: bottom; /* Align button properly */
  }
`;

const animationTopEnterStyles = css`
  animation: slideInFromTop 0.5s ease-out forwards;
`;

const animationTopExitStyles = css`
  animation: slideOutToTop 0.5s ease-out forwards;
`;
