import React from "react";
import "./dynamic.css";

function Dynamic() {

  return (
    <>
      <div className="overDiv">
        <div className="ddd divStyle1">
          <img
            className="skills"
            src="/bootstrap.svg.png"
            alt="Bootstrap logo"
          />
        </div>
        <div className="ddd divStyle2">
          <img className="skills" src="/html5.svg.png" alt="HTML5 logo" />
        </div>
        <div className="ddd divStyle3">
          <img className="skills" src="/css3.svg.png" alt="CSS3 logo" />
        </div>
        <div className="ddd divStyle4">
          <img className="skills" src="/mongoDB.svg.png" alt="MongoDB logo" />
        </div>
        <div className="ddd divStyle5">
          <img className="skills" src="/node.svg.png" alt="Node.js logo" />
        </div>
        <div className="ddd divStyle6">
          <img className="skills" src="/react.svg.png" alt="React.js logo" />
        </div>
        <div className="ddd divStyle7">
          <img className="skills" src="/redux.svg.png" alt="Redux logo" />
        </div>
      </div>
    </>
  );
}

export default Dynamic;
