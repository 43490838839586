import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import {
  VerticalLine,
  JobDiv,
  ProfileCircle,
  TitleText,
} from "./StyledComponents";
import { Row, Col, Container, Button } from "react-bootstrap";
import JobDetailsModal from "./JobDetailsModal";
import SkillFilterModal from "./SkillFilterModal"; // Import the new component
import "./ProfessionalExperience.css";

const calculateInitialLines = (isMobile, jobCount, descriptionAdjustment) => {
  const lines = Array.from({ length: jobCount }, (_, index) => ({
    left: isMobile ? "50%" : `${45 + (index % 2) * 10}%`,
    top: isMobile
      ? `${
          250 * index +
          (index === 0 ? 0 : 330) +
          (index === 0 ? 0 : descriptionAdjustment)
        }px`
      : `${
          250 * index +
          (index === 0 ? 0 : 130) +
          (index === 0 ? 0 : descriptionAdjustment)
        }px`,
    height: "0px",
    endHeight: isMobile
      ? `${index === 0 ? 430 + descriptionAdjustment : 100}px`
      : `${index === 0 ? 230 + descriptionAdjustment : 100}px`,
  }));

  lines.length > 0 &&
    lines.push({
      left: isMobile ? "50%" : `${45 + (jobCount % 2) * 10}%`,
      top: isMobile
        ? `${330 + 250 * jobCount + descriptionAdjustment}px`
        : `${115 + 252 * jobCount + descriptionAdjustment}px`,
      height: "0px",
      endHeight: isMobile ? `${500}px` : `${700}px`,
    });

  return lines;
};

const ProfessionalExperience = ({ isAnimating, jobs }) => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [visibleDivs, setVisibleDivs] = useState({});
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [descriptionAdjustment, setDescriptionAdjustment] = useState(0);

  const normalizeSkill = (skill) =>
    skill
      .trim()
      .toLowerCase()
      .replace(/\s+/g, " ")
      .replace(/(^|\s)\S/g, (l) => l.toUpperCase());

  const uniqueSkills = useMemo(() => {
    return [
      ...new Set(
        jobs.flatMap((job) => job.skills.map((skill) => normalizeSkill(skill)))
      ),
    ].sort();
  }, [jobs]);

  const filteredJobs = useMemo(() => {
    if (selectedSkills.length === 0) return jobs;
    const normalizedSelectedSkills = selectedSkills.map((skill) =>
      skill.toLowerCase()
    );
    return jobs.filter((job) =>
      normalizedSelectedSkills.every((selectedSkill) =>
        job.skills.some((jobSkill) => jobSkill.toLowerCase() === selectedSkill)
      )
    );
  }, [selectedSkills, jobs]);

  const [lines, setLines] = useState(
    calculateInitialLines(
      window.innerWidth <= 991,
      filteredJobs.length,
      descriptionAdjustment
    )
  );

  const handleAnimation = useCallback(() => {
    filteredJobs.forEach((_, index) => {
      const delay = index * 400 + 100;

      setTimeout(() => {
        setLines((lines) =>
          lines.map((line, lineIndex) =>
            lineIndex === index ? { ...line, height: line.endHeight } : line
          )
        );
      }, delay);

      setTimeout(
        () =>
          setVisibleDivs((prev) => ({ ...prev, [`div${index + 1}`]: true })),
        delay + 400
      );
    });

    const lastLineDelay = filteredJobs.length * 400 + 100;
    setTimeout(() => {
      setLines((lines) =>
        lines.map((line, index) =>
          index === filteredJobs.length
            ? { ...line, height: line.endHeight }
            : line
        )
      );
    }, lastLineDelay);
  }, [filteredJobs]);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        const newLines = calculateInitialLines(
          window.innerWidth <= 991,
          filteredJobs.length,
          titleTextRef.current
            ? titleTextRef.current.clientHeight
            : descriptionAdjustment
        );
        // Directly set the lines with their endHeight
        const updatedLines = newLines.map(line => ({
          ...line,
          height: line.endHeight,
        }));
        setLines(updatedLines);
      }, 100);
    };
  
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [filteredJobs.length, descriptionAdjustment, handleAnimation]);
  

  useEffect(() => {
    setLines((lines) => lines.map((line) => ({ ...line, height: "0px" })));
    handleAnimation();
  }, [location.pathname, filteredJobs, handleAnimation]);

  const handleOpenModal = (job) => {
    setSelectedJob(job);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSkillChange = (event) => {
    const { value, checked } = event.target;
    setSelectedSkills((prevSkills) =>
      checked
        ? [...prevSkills, value]
        : prevSkills.filter((skill) => skill !== value)
    );
  };

  const toggleFiltersModal = () => {
    setTimeout(() => {
      setShowFiltersModal((prevShowFilters) => !prevShowFilters);
    }, 500);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const resetFilters = () => {
    setSelectedSkills([]);
    setSearchTerm("");
  };

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };
  const titleTextRef = useRef(null);

  useEffect(() => {
    if (titleTextRef.current) {
      setDescriptionAdjustment(titleTextRef.current.clientHeight);
    }
  }, [showMore]);

  useEffect(() => {
    const newLines = calculateInitialLines(
      window.innerWidth <= 991,
      filteredJobs.length,
      descriptionAdjustment
    );
    setLines(newLines);
    handleAnimation();
  }, [filteredJobs, descriptionAdjustment, handleAnimation]);

  return (
    <>
      <div id="gifexperience"></div>
      <Container
        fluid
        className="p-2 mt-5"
        style={{ backgroundColor: "transparent" }}
      >
        <Row className="mt-5">
          <Col xs={12} sm={12} md={12} lg={5} xl={4} xxl={3}>
            <ProfileCircle animate={isAnimating}>
              <img
                src="https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/238765395_6659303810762401_5497579976259752615_n.jpg?updatedAt=1718246865269"
                alt="Profile pic"
                title="Profile pic"

              />
            </ProfileCircle>
          </Col>
          <Col xs={12} sm={12} md={12} lg={7} xl={8} xxl={9} ref={titleTextRef}>
            {window.innerWidth <= 991 && (
              <h4
                style={{ position: "relative ", zIndex: "10" }}
              >
                Professional Experience
              </h4>
            )}
            <TitleText animate={isAnimating} showMore={showMore}>
              {window.innerWidth <= 991 ? (
                <span className="description">
                  With a strong foundation in Full Stack Development and
                  Software Architecture, I have contributed to top-tier
                  companies such as Datto, TikTok, and Accenture. My work has
                  involved developing innovative software solutions, enhancing
                  user interfaces, and optimizing system performance. At Datto,
                  I led the transition to React for the Portal website and
                  crafted reusable components for a DattoUI Storybook. At
                  TikTok, I significantly improved content accuracy and
                  integrity by implementing advanced data analytics and
                  optimizing system performance. At Accenture, I designed and
                  deployed Chrome extensions and JavaScript automation scripts
                  to streamline content verification tasks. Explore my
                  professional journey to see how my skills and contributions
                  have consistently exceeded expectations and delivered
                  high-quality results.
                </span>
              ) : (
                <>
                  <h4
                    style={{ position: "relative ", zIndex: "10" }}
                  >
                    Professional Experience
                  </h4>
                  <p className="description">
                    With a strong foundation in Full Stack Development and
                    Software Architecture, I have contributed to top-tier
                    companies such as Datto, TikTok, and Accenture. My work has
                    involved developing innovative software solutions, enhancing
                    user interfaces, and optimizing system performance. At
                    Datto, I led the transition to React for the Portal website
                    and crafted reusable components for a DattoUI Storybook. At
                    TikTok, I significantly improved content accuracy and
                    integrity by implementing advanced data analytics and
                    optimizing system performance. At Accenture, I designed and
                    deployed Chrome extensions and JavaScript automation scripts
                    to streamline content verification tasks. Explore my
                    professional journey to see how my skills and contributions
                    have consistently exceeded expectations and delivered
                    high-quality results.
                  </p>
                </>
              )}
              <span
                className="toggle-button"
                onClick={toggleShowMore}
                style={{ fontSize: "0.5em" }}
              >
                {showMore ? "Show Less" : "View All"}
              </span>
            </TitleText>
          </Col>
        </Row>
        <Row
          className="mt-3"
          style={{ position: "absolute", zIndex: 10, width: "100%" }}
        >
          <Col xs={12} className="d-flex justify-content-start">
            {
              <>
                <Button
                  onClick={toggleFiltersModal}
                  className={`custom-button ${
                    isAnimating ? "button-exit-active" : "button-enter-active"
                  }`}
                >
                  {showFiltersModal ? "Hide Filters" : "Show Filters"}
                </Button>
                <Button
                  onClick={resetFilters}
                  className={`custom-button ${
                    isAnimating ? "button-exit-active" : "button-enter-active"
                  }`}
                >
                  Reset Filters
                </Button>
              </>
            }
          </Col>
        </Row>
        {lines.map((line, index) => (
          <VerticalLine
            key={index}
            left={line.left}
            top={line.top}
            height={line.height}
            animate={isAnimating}
          />
        ))}
        {filteredJobs.map((job, index) => (
          <JobDiv
            key={index}
            left={
              window.innerWidth <= 991 ? "0%" : index % 2 === 0 ? "0%" : "25%"
            }
            marginTop={window.innerWidth <= 991 ? `${100}px` : `${100}px`}
            animate={isAnimating}
            visible={visibleDivs[`div${index + 1}`]}
            onClick={() => handleOpenModal(job)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexWrap: "wrap",
                textAlign: "center",
              }}
            >
              {job.duration} - {job.title} at {job.company}
            </div>
          </JobDiv>
        ))}
        {selectedJob && (
          <JobDetailsModal
            showModal={showModal}
            handleClose={handleCloseModal}
            job={selectedJob}
          />
        )}
        <SkillFilterModal
          show={showFiltersModal}
          handleClose={toggleFiltersModal}
          selectedSkills={selectedSkills}
          handleSkillChange={handleSkillChange}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          uniqueSkills={uniqueSkills}
        />
      </Container>
    </>
  );
};

export default ProfessionalExperience;
