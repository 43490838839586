const education = [
  {
    degree:
      "MSc in Software Design with Cloud Native Computing (NFQ level 9 - EQF Level 7)",
    institution: "Technological University of the Shannon",
    duration: "Sep 2022 - Nov 2023",
    location: "Athlone, Ireland",
    grade: "First Class Honours (GPA 4.0)",
    start: "2022-09-01",
    end: "2023-11-01",
    imageUrls: [
      "https://media.licdn.com/dms/image/C4E1BAQFEln7gN4Mv5A/company-background_10000/0/1633103779215/tusmidlands_cover?e=2147483647&v=beta&t=qNcaWOrccR6wZ07bYzzY6Ts3A2p0RNkucfPUzZ2xhIA",
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/TUS_Athlone_Engineering_%26_Science_Building%2C_2022-12-20.jpg/1200px-TUS_Athlone_Engineering_%26_Science_Building%2C_2022-12-20.jpg",
    ],
    imageTitles: ["TUS Logo", "TUS Engineering & Science Building"],
    courses: [
      "Applied Scripting Languages (Python)",
      "Container Design and Deployment (AWS, Docker, Kubernetes, Docker Swarm)",
      "Continuous Build and Delivery (SCRUM, Git, Maven, Jenkins, SonarQube, JUnit tests, BDD with Karate testing framework, Integration Tests, Contract Testing, Ansible, AWS, Docker)",
      "Engineering Team Project (Java Spring, Eureka discovery service, JWT authentication, fully deployed pipeline)",
      "Microservices architecture (RESTful web services, Springboot, Spring Data, Spring Cloud Config, Eureka Discovery Service, Hystrix Resiliency Patterns, API Gateways, Sleuth and Zipkin tracing, Oauth2 and JWT)",
      "Objected Oriented Programming I (Java)",
      "Objected Oriented Programming II (Java)",
      "Research Methods and Professional Placement (6 sigma)",
      "Work Placement - Graduation Project at Datto (Kaseya) - Krakow",
    ],
    skills: [
      "Spring Boot",
      "Spring Framework",
      "MySQL",
      "Kubernetes",
      "JUnit",
      "Amazon Web Services (AWS)",
      "SQL",
      "Numerical Analysis",
      "Full-Stack Development",
      "Docker Products",
      "Java",
      "Public Speaking",
      "Computer Science",
      "Linux",
      "Python",
      "Object-Oriented Programming (OOP)",
      "Scrum",
      "Microservices",
      "RESTful Web Services",
      "Spring Data",
      "Spring Cloud Config",
      "Eureka Discovery Service",
      "Hystrix Resiliency Patterns",
      "API Gateways",
      "Sleuth",
      "Zipkin Tracing",
      "OAuth2",
      "JWT Authentication",
      "BDD with Karate Testing Framework",
      "Integration Tests",
      "Contract Testing",
      "Ansible",
      "CI/CD Pipelines",
      "Cloud Native Computing",
      "Container Orchestration",
      "Serverless Architecture",
      "DevOps",
      "Agile Methodologies",
      "Software Design Patterns",
      "Continuous Deployment",
      "Continuous Integration",
      "Test-Driven Development (TDD)",
      "Behavior-Driven Development (BDD)",
      "System Architecture",
      "API Development",
      "Data Analytics",
      "Machine Learning",
      "Cloud Computing",
      "Infrastructure as Code (IaC)",
      "Version Control (Git)",
    ],
    mapLocation:
      "TUS: Midlands Engineering & Science Building, Athlone, Ireland",
  },
  {
    degree:
      "Higher Diploma in Science of Computing (NFQ level 8 - EQF Level 6)",
    institution: "Atlantic Technological University",
    duration: "Sep 2021 - Oct 2022",
    location: "Letterkenny, Ireland",
    grade: "First Class Honours (GPA 4.0)",
    start: "2021-09-01",
    end: "2022-06-01",
    imageUrls: [
      "https://international-sustainable-campus-network.org/wp-content/uploads/2021/06/Atlantic-Technological-University_sm.png",
      "https://donegal.ie/media/1214/large-hero-lyit.jpg",
    ],
    imageTitles: [
      "Atlantic Technological University Logo",
      "Atlantic Technological University",
    ],
    courses: [
      "Computer Systems (Unix System Admin)",
      "Database Technology (SQL)",
      "Software Development (JAVA)",
      "Software Development & Testing (JAVA)",
      "Software Engineering & Quality Assessment",
      "Internet Applications Development (HTML, CSS, PHP)",
    ],
    skills: [
      "MySQL",
      "SQL",
      "CSS",
      "HTML",
      "Full-Stack Development",
      "Java",
      "Computer Science",
      "Linux",
      "English",
      "Object-Oriented Programming (OOP)",
      "Scrum",
      "PHP",
      "JavaScript",
      "React.js",
      "Node.js",
      "Express.js",
      "RESTful API Development",
      "GraphQL",
      "Agile Methodologies",
      "Version Control (Git, GitHub, GitLab)",
      "Unit Testing (JUnit, Mockito)",
      "Integration Testing",
      "Continuous Integration/Continuous Deployment (CI/CD)",
      "DevOps Practices",
      "Cloud Computing (Google Cloud Platform - GCP)",
      "Containerization (Docker, Kubernetes)",
      "Microservices Architecture",
      "Data Structures and Algorithms",
      "Software Design Patterns",
      "Web Development",
      "Responsive Web Design",
      "Cross-Browser Compatibility",
      "Web Performance Optimization",
      "Security Best Practices",
      "Database Management",
      "ORM (Hibernate, JPA)",
      "Software Documentation",
      "Technical Writing",
      "API Documentation",
      "Debugging and Troubleshooting",
      "Problem Solving",
      "Analytical Thinking",
      "Team Collaboration",
      "Project Management Tools (JIRA, Trello)",
      "Time Management",
      "Effective Communication",
      "Critical Thinking",
      "Presentation Skills",
      "Networking Concepts",
      "Virtualization (VMware, VirtualBox)",
      "Shell Scripting (Bash)",
      "Automated Testing",
      "Behavior-Driven Development (BDD)",
      "Test-Driven Development (TDD)",
      "Code Review",
      "Refactoring",
      "Performance Testing",
      "Load Testing",
      "Penetration Testing",
      "Ethical Hacking",
      "User Experience (UX) Design",
      "User Interface (UI) Design",
      "Graphic Design Tools (Adobe Photoshop, Illustrator)",
      "Mobile Application Development (Android, iOS)",
      "API Development and Integration",
      "Middleware Technologies",
      "Enterprise Application Development",
      "Big Data Technologies (Hadoop, Spark)",
      "Data Visualization",
      "Machine Learning Basics",
      "Artificial Intelligence Basics",
      "Software Prototyping",
      "Innovation and Creativity",
      "Research and Development (R&D)",
      "Leadership and Mentoring",
    ],
    mapLocation:
      "Atlantic Technological University - Donegal Letterkenny Campus, Letterkenny, Ireland",
  },
  {
    degree: "Bootcamp - Node.js FullStack Development",
    institution: "EPICODE",
    duration: "Apr 2020 - Oct 2020",
    location: "Remote",
    start: "2020-04-01",
    end: "2020-10-01",
    imageUrls: ["https://epicode.pl/wp-content/uploads/2019/07/logo.png"],
    imageTitles: ["EPICODE Logo"],
    courses: [
      "HTML/CSS",
      "JavaScript",
      "Bootstrap",
      "React (with Redux and Hooks)",
      "AJAX, HTTP, ES6",
      "Express and Express middlewares",
      "Relational and non-relational databases (MongoDB and PostgreSQL)",
      "Authentication",
      "Cloud (focused on Microsoft Azure services)",
    ],
    skills: [
      "HTML5",
      "CSS3",
      "JavaScript (ES6+)",
      "React.js (Hooks, Redux)",
      "Bootstrap (Framework)",
      "AJAX",
      "HTTP/HTTPS",
      "RESTful APIs",
      "Express.js",
      "Node.js",
      "MongoDB",
      "PostgreSQL",
      "SQL",
      "NoSQL",
      "Authentication (JWT, OAuth)",
      "Microsoft Azure",
      "Cloud Computing",
      "Docker",
      "Kubernetes",
      "Microservices",
      "API Development",
      "Version Control (Git)",
      "Continuous Integration/Continuous Deployment (CI/CD)",
      "Agile Methodologies (Scrum)",
      "Unit Testing (Jest, Mocha, Chai)",
      "Integration Testing",
      "Responsive Web Design",
      "Full-Stack Development",
      "Server-Side Rendering",
      "GraphQL",
      "Public Speaking",
      "English",
      "Team Collaboration",
      "Problem Solving",
      "Debugging",
      "Software Architecture",
      "Performance Optimization",
    ],
  },
  {
    degree: "Engineering Physics - Nuclear Fusion Science",
    institution: "Universite de Lorraine",
    duration: "Sep 2014 - Sep 2015",
    location: "Nancy, France",
    start: "2014-09-01",
    end: "2015-09-01",
    imageUrls: [
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Logo_Universit%C3%A9_de_Lorraine.svg/2560px-Logo_Universit%C3%A9_de_Lorraine.svg.png",
      "https://fst.univ-lorraine.fr/sites/fst.univ-lorraine.fr/files/images/fst-yp-batiment-victor-grignard.jpg",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Nancy1.jpg?updatedAt=1718197069728",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Nancy3.jpg?updatedAt=1718197069617",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Nancy2.jpg?updatedAt=1718197069822",
    ],
    imageTitles: [
      "Universite de Lorraine Logo",
      "Campus de la Faculté des Sciences et Technologies",
      "From left to right, Bezia Lemma, Gustavo Pedro Ricou, Artur Vander Sande, Guillermo Suarez and Arvydas Šepetys",
      "Photo from Hotel de Ville, Place Stanislas",
      "Christmas in Strasbourg",
    ],
    activities: ["Handball", "Basketball", "Football", "Volleyball"],
    courses: [
      "Advanced Condensed Matter Physics",
      "Advanced Physical Laboratory",
      "Advanced Statistical Physics",
      "Fusion Research",
      "German for Foreigners Intensive Course",
      "Numerical Plasma Physics I",
      "Numerical Plasma Physics II",
      "Plasma applications part I: Plasma Technology",
      "Plasma Physics I",
      "Plasma Physics II",
      "Solid State Spectroscopy",
      "Extra-course: Advanced Quantum Theory",
      "Advanced Plasma Physics Research",
      "Diagnostics for fluctuations and advanced data processing methods",
      "French Language and Culture",
      "Plasma Turbulence, Transport and Heating",
      "Plasma-Wall Interactions",
      "Tokamaks: Operation and Research",
    ],
    skills: [
      "Python",
      "NumPy",
      "SciPy",
      "Matlab",
      "SQL",
      "Microsoft Excel",
      "Data Analysis and Visualization",
      "Advanced Python Development",
      "Scientific Computing",
      "Scientific Research",
      "Numerical Analysis",
      "Advanced Statistical Techniques",
      "Quantum Mechanics",
      "Electromagnetism",
      "Condensed Matter Physics",
      "Plasma Physics",
      "Materials Science",
      "Plasma Diagnostics",
      "Technical Reporting and Presentation",
      "Research Methodologies",
      "Problem Solving",
      "Collaborative Research",
      "Scientific Writing",
      "Project Management",
      "Interdisciplinary Collaboration",
      "French",
      "Physics",
    ],
    mapLocation: "Faculté des Sciences et Technologies, Nancy, France",
  },
  {
    degree: "Engineering Physics - Nuclear Fusion Science",
    institution: "Universität Stuttgart",
    duration: "Sep 2013 - Sep 2014",
    location: "Stuttgart, Germany",
    start: "2013-09-01",
    end: "2014-09-01",
    imageUrls: [
      "https://www.beschaeftigte.uni-stuttgart.de/uni-services/oeffentlichkeitsarbeit/corporate-design/cd-dateien/01_Logo/png/unistuttgart_logo_englisch_cmyk_invertiert.png",
      "https://www.f08.uni-stuttgart.de/en/study_programs/physics/images/V57_Universitaet_Stuttgart.jpg?__scale=w:1000,h:1000,q:100,t:3",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Stuttgart2.jpg?updatedAt=1718218279068",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Stuttgart1.jpg?updatedAt=1718218279075",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Stuttgart6.jpg?updatedAt=1718218278979",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Stuttgart3.jpg?updatedAt=1718218278966",
    ],
    imageTitles: [
      "Universität Stuttgart Logo",
      "Faculty 8 - Physics and Mathematics, Universität Stuttgart",
      "From left to right, Gustavo Pedro Ricou, Guillermo Suarez, Egor Seliunin and Pranay Valson, at  Institut für Grenzflächenverfahrenstechnik und Plasmatechnologie, Universität Stuttgart",
      "On the back row, left to right, Mikko Poikkimäki, Johan Zet Briones Paz, Gennadii Laskin, Pranay Valson, Heino Soo, Gustavo Pedro Ricou, Kim Hun-ho, Shreyas Muralidhar, Kushal Ramakrishna, Anurag Kanase, Theodoros Tsatsoulis, Sergio Cruz. On front row, Mahsa Sahebdivani, Jiyeong Kim-Poikkimäki, Namrata Gurung. At the Internationalen Zentrum (IZ) auf dem Campus Stuttgart-Vaihingen",
      "From left to right, Nicole Chia, Gustavo Pedro Ricou and Dionne Lee, at Straußäcker 3, Vaihingen-Stuttgart",
      "Field study of the German Language Class.",
    ],
    activities: [
      "Handball",
      "Basketball",
      "Football",
      "Volleyball",
      "President of the Fiscal Council - Physis - Portuguese Association of Physics Students (organizer of the National Encounter of Physics Students)",
    ],
    courses: [
      "Advanced Condensed Matter Physics",
      "Advanced Physical Laboratory",
      "Advanced Statistical Physics",
      "Fusion Research",
      "German for Foreigners Intensive Course",
      "Numerical Plasma Physics I",
      "Numerical Plasma Physics II",
      "Plasma applications part I: Plasma Technology",
      "Plasma Physics I",
      "Plasma Physics II",
      "Solid State Spectroscopy",
      "Extra-course: Advanced Quantum Theory",
      "Advanced Plasma Physics Research",
      "Diagnostics for fluctuations and advanced data processing methods",
      "French Language and Culture",
      "Plasma Turbulence, Transport and Heating",
      "Plasma-Wall Interactions",
      "Tokamaks: Operation and Research",
    ],
    skills: [
      "Python",
      "NumPy",
      "SciPy",
      "Matlab",
      "SQL",
      "Microsoft Excel",
      "Microsoft Office",
      "Data Analysis and Visualization",
      "Scientific Computing",
      "Advanced Statistical Techniques",
      "Numerical Analysis",
      "Research",
      "Mathematical Modeling",
      "Simulation and Modeling",
      "Plasma Physics",
      "Materials Science",
      "Experimental Physics",
      "Condensed Matter Physics",
      "Plasma Diagnostics",
      "Technical Reporting and Presentation",
      "Scientific Writing",
      "Project Management",
      "Leadership",
      "Public Speaking",
      "English",
      "Physics",
    ],
    mapLocation: "Fachbereich Mathematik, Stuttgart, Germany",
  },
  {
    degree: "Bachelor's degree in Physics (EQF level 6)",
    institution: "Universidade do Porto",
    duration: "Sep 2008 - Sep 2013",
    location: "Porto, Portugal",
    grade: "15 (GPA 3.6 - Third best out of 50)",
    start: "2008-09-01",
    end: "2013-09-01",
    imageUrls: [
      "https://www.agrisource.org/medias/642db231-737d-41e0-b472-e6b6825dc595.jpg",
      "https://www.fc.up.pt/dfa/wp-content/uploads/Interior_DFA-DGAOT.jpg",
      "https://scontent.flcj1-1.fna.fbcdn.net/v/t31.18172-8/322711_260299597354143_1277466697_o.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=7TaFj4gN5zkQ7kNvgF2iHMY&_nc_ht=scontent.flcj1-1.fna&oh=00_AYBBPWyAe_5GOdQtEQ2UR7HVfnp_sZEuYs7UlC7Aty3bxQ&oe=669185FA",
      "https://scontent.flcj1-1.fna.fbcdn.net/v/t31.18172-8/328653_258355837548519_1123884662_o.jpg?_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=jS-TCV2igsAQ7kNvgEUraY_&_nc_ht=scontent.flcj1-1.fna&oh=00_AYDY0z88SRWRSKgvNcObdgIXghlV-v3q_9ceO7Vh88Fgnw&oe=66917552",
      "https://scontent.flcj1-1.fna.fbcdn.net/v/t31.18172-8/334682_260293227354780_501645160_o.jpg?_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=L2CAfu2Vi_kQ7kNvgHsn2Oa&_nc_ht=scontent.flcj1-1.fna&oh=00_AYBFGtrxC_hguSCDro8UBNngpyy6uH6q2XxegD9LcwMsbA&oe=6691852E",
      "https://scontent.flcj1-1.fna.fbcdn.net/v/t31.18172-8/903889_537759279608172_1445402916_o.jpg?_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=uzFUthY8KkwQ7kNvgGl_t0N&_nc_ht=scontent.flcj1-1.fna&oh=00_AYBjzFM-tMWHfTa2iJMa-VZon7DeONe3g5mxJKSoDffxTA&oe=66918441",
      "https://scontent.flcj1-1.fna.fbcdn.net/v/t31.18172-8/882796_537764422940991_376112769_o.jpg?_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_ohc=3NPDMS8qTYIQ7kNvgGMiZIZ&_nc_ht=scontent.flcj1-1.fna&oh=00_AYAMsB7oqxzanP8V7IKQoyGgtQT5lUU6jf9uqwMv7F7bfA&oe=66917EB9",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Porto1.jpg?updatedAt=1718223743468",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Porto2.jpg?updatedAt=1718223967566",
    ],
    imageTitles: [
      "Universidade do Porto (Faculty of Sciences) Logo",
      "Interior of the Department of Physics and Astronomy of the Faculty of Sciences of the University of Porto",
      "From left to right, Jose Pedro Vieira, Pedro Sarmento, Daniel Passos, Joao Moura, Gustavo Pedro Ricou, Flavio de Sousa Coelho. On the bottom, Mafalda Monteiro. At the Department of Physics of the Faculty of Sciencs of the University of Porto, during the National Encounter of Physics Students 2011, Chess tournament.",
      "Group picture of the National Encounter of Physics Students at the University of Porto 2011",
      "Graphene Lecture, National Encounter of Physics Students at the University of Porto 2011, Gustavo Pedro Ricou and Mafalda Maia",
      "Group picture of the National Encounter of Physics Students at the University of Evora 2012",
      "Winners of the Pubs Rally at the National Encounter of Physics Students at the University of Coimbra 2013",
      "Jazz Nights, organized by the Cultural Department of the Faculty of Sciences Student Association",
      "Volleyball Game (our victory), at the Faculty of Sports of the University of Porto",
    ],
    activities: [
      "Direction of AEFCUP - Student Association (Department of Education's Policy and Cultural Department)",
      "Basketball Team",
      "Volleyball Team",
      "General Secretary - Physis - Portuguese Association of Physics Students (organizer of the National Encounter of Physics Students)",
    ],
    courses: [
      "Classical Mechanics",
      "Electromagnetism",
      "Fundamentals of Chemistry",
      "Linear Algebra",
      "Infinitesimal Calculus I",
      "Infinitesimal Calculus II",
      "Introduction to Programming (Python)",
      "Physics Laboratory I",
      "Communication Techniques",
      "Structure and Reactivity in Inorganic Chemistry",
      "Thermal Physics",
      "Waves and Continuous Media",
      "Complements of Classical Physics",
      "Electronics",
      "Physics Laboratory II",
      "Vector Analysis",
      "Infinitesimal Analysis",
      "Probability and Statistics",
      "Introduction to Modern Physics and Astrophysics",
      "Quantum Mechanics",
      "Electrodynamics and Relativity",
      "Statistical and Computational Physics",
      "Elasticity and Fluid Dynamics",
      "Condensed Matter Physics",
      "Physics Laboratory III",
      "Optics",
      "Simulation",
      "Nuclear Chemistry and Radio Chemistry",
    ],
    skills: [
      "Python",
      "NumPy",
      "SciPy",
      "Matlab",
      "LabVIEW",
      "SQL",
      "Linux",
      "Microsoft Excel",
      "Microsoft Office",
      "Data Analysis and Visualization",
      "Numerical Analysis",
      "Scientific Computing",
      "Advanced Statistical Techniques",
      "Physics",
      "Plasma Physics",
      "Condensed Matter Physics",
      "Electronics",
      "Quantum Mechanics",
      "Experimental Physics",
      "Mathematical Modeling",
      "Technical Reporting and Presentation",
      "Scientific Writing",
      "Project Management",
      "Leadership",
      "Public Speaking",
      "Academic Tutoring",
      "Research",
      "Portuguese",
      "English",
    ],
    mapLocation: "Departamento de Física e Astronomia FCUP, Porto, Portugal",
  },
];

export default education;
