import React, { useMemo } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import "./JobDetailsModal.css";

const JobDetailsModal = ({ showModal, handleClose, job = {} }) => {
  const {
    title = "No Title",
    company = "No Company",
    duration = "No Duration",
    location = "No Location",
    duties = [],
    imageUrls = [],
    imageTitles = [],
    mapLocation,
    skills = [],
  } = job;

  const totalImages = imageUrls.length + (mapLocation ? 1 : 0);
  const numColumns = totalImages * 2;

  const imagePositions = useMemo(() => {
    let positions = [];
    for (let i = 0; i < totalImages / 2; i++) {
      positions.push(0 + 4 * i);
      positions.push(3 + 4 * i);
    }
    if (totalImages % 2 !== 0) {
      positions.pop();
    }

    return positions;
  }, [totalImages]);

  const columns = useMemo(() => {
    let columns = Array.from({ length: numColumns }, () => ({
      content: [],
      imageSrc: "",
      imageTitle: "",
      isPictureLeft: false,
    }));

    imageUrls.forEach((url, index) => {
      columns[imagePositions[index]] = {
        imageSrc: url,
        imageTitle: imageTitles[index] || "",
        isPictureLeft: imagePositions[index] % 2 === 0,
      };
    });

    if (mapLocation && imagePositions.length > 0) {
      columns[imagePositions[imageUrls.length]] = {
        imageSrc: `https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
          mapLocation
        )}&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C${encodeURIComponent(
          mapLocation
        )}&key=AIzaSyCJybyRrlJDXJUIKFLQKzjEsorwQ_GzLOM`,
        imageTitle: "Map Location",
        isPictureLeft: imagePositions[imageUrls.length] % 2 === 0,
        content: [],
      };
    }

    const dutyColumns = imagePositions.map((pos) => {
      if (pos % 4 === 0) {
        return pos + 1;
      } else if (pos % 4 === 3) {
        return pos - 1;
      } else {
        return pos;
      }
    });

    duties.forEach((duty, i) => {
      const columnIndex = dutyColumns[i % dutyColumns.length];
      if (columns[columnIndex]) {
        columns[columnIndex].content.push(duty);
      }
    });

    return columns;
  }, [duties, imageUrls, mapLocation, numColumns, imagePositions, imageTitles]);

  if (!showModal) return null;

  return (
    <Modal show={showModal} onHide={handleClose} dialogClassName="modal-90w">
      <Modal.Header closeButton style={{ backgroundColor: "#333" }}>
        <Modal.Title className="degreeModal">
          <p>{title}</p>
          <p> at {company}</p>
          <p>
            {duration} - {location}
          </p>
          <p style={{ color: "#e0f7fa", fontSize: "0.5em" }}>
            {skills.map((skill, index) => (
              <span key={index}>#{skill} </span>
            ))}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#333" }}>
        <Row>
          {columns.map((col, index) => (
            <Col
              key={index}
              md={6}
              xs={12}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {index === 0 || index % 4 === 0 || index % 4 === 3 ? (
                <div style={{ flex: "1 1 auto", minHeight: "200px" }}>
                  <img
                    src={col.imageSrc}
                    alt={`Visual context for ${title}`}
                    style={{
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                  {col.imageTitle && (
                    <p
                      style={{
                        fontSize: "0.8em",
                        textAlign: "center",
                        marginTop: "5px",
                      }}
                    >
                      {col.imageTitle}
                    </p>
                  )}
                </div>
              ) : (
                col.content.length > 0 && (
                  <div
                    style={{
                      flex: "1 1 auto",
                      overflowY: "auto",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ul style={{ listStyleType: "disc", paddingLeft: "25px" }}>
                      {col.content.map((duty, dutyIndex) => (
                        <li key={dutyIndex}>{duty}</li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </Col>
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#333" }}>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default JobDetailsModal;
