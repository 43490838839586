import React from "react";
import "./footer.css";

function Footer() {
  const currentYear = new Date().getFullYear(); // Gets the current year

  return (
    <footer className="footer-style">
      <div className="row footer-content">
        {/* Add content to these divs or remove them if not needed */}
        <div className="col-4">{/* Content (if any) */}</div>
        <div className="col-4">{/* Content (if any) */}</div>
        <div className="col-4">
          <p>© Gustavo Pedro Ricou {currentYear}, all rights reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
