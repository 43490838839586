import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import "./home.css";

const animationTopEnterStyles = css`
  animation: slideInFromTop 0.5s ease-out forwards;
`;

const animationTopExitStyles = css`
  animation: slideOutToTop 0.5s ease-out forwards;
`;

const ProfileCircle = styled.div`
  width: 300px;
  height: 300px;
  background-color: #ddd;
  border-radius: 50%;
  margin-left: 100px;
  margin-top: 0px;
  ${(props) =>
    props.animate ? animationTopExitStyles : animationTopEnterStyles}
  position:relative;
  z-index: 1;
  overflow: hidden; /* Ensure overflow is hidden to clip the image */

  & > img {
    width: 100%; /* Ensure image covers the container */
    height: 170%;
    object-fit: cover;
    position: absolute;
  }
  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    height: 200px;
  }
`;

const TitleText = styled.div`
  margin-left: 60px;
  color: white;
  ${(props) =>
    props.animate ? animationTopExitStyles : animationTopEnterStyles}
  position: relative;
  z-index: 1;
  & > .description {
    margin-bottom: 0px;
    width: 100%;
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (props.showMore ? "unset" : 3)};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 991px) {
      width: 70%;
      font-size: 15px;
      white-space: ${(props) =>
        props.showMore ? "" : "nowrap"}; /* Make text in a single line */
      overflow: hidden;
      text-overflow: ellipsis;
      display: ${(props) =>
        props.showMore ? "inline" : "block"}; /* Toggle display */
    }
  }
  @media (max-width: 991px) {
    margin-left: 0px;
    display: ${(props) => (props.showMore ? "" : "flex")};
  }
  .toggle-button {
    font-weight: bold;
    cursor: pointer;
    color: white;
    display: inline-block;
    margin-left: 5px;
    vertical-align: bottom; /* Align button properly */
  }
`;

// Define similar exit styles for Sections and apply conditionally
const Section = styled.div`
  display: flex;
  align-items: center;
  color: white;
  margin: 10px 0;
  padding: 20px;
  border-radius: 8px;
  background: ${(props) =>
    props.direction === "left"
      ? "linear-gradient(to right, #333, transparent)"
      : "linear-gradient(to left, #333, transparent)"};
  animation: ${(props) =>
      props.animate
        ? props.direction === "left"
          ? "slideOutToLeft"
          : "slideOutToRight"
        : props.direction === "left"
        ? "slideInFromRight"
        : "slideInFromLeft"}
    0.5s ease-out forwards;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    filter: brightness(2);
    background: ${(props) =>
      props.direction === "left"
        ? "linear-gradient(to right, #555, transparent)"
        : "linear-gradient(to left, #555, transparent)"};
  }

  @media (min-width: 768px) {
    width: 65.67%; // 2/3 width
    margin-left: ${(props) => (props.direction === "left" ? "1%" : "33.33%")};
    margin-right: ${(props) => (props.direction === "left" ? "33.33%" : "1%")};
    justify-content: ${(props) =>
      props.direction === "left" ? "flex-start" : "flex"};
  }
  flex-direction: ${(props) =>
    props.direction === "left" ? "row" : "row-reverse"};
`;

const ImgPlaceholder = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-left: ${(props) => (props.direction === "left" ? "0" : "20px")};
  margin-right: ${(props) => (props.direction === "left" ? "20px" : "0")};
  background-image: url(${(props) => props.imageURL});
  background-size: cover;
  background-position: center;
  filter: brightness(0.8);
`;

const Home = ({ isAnimating, startAnimation }) => {
  const [showMore, setShowMore] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  const handleSectionClick = (path) => {
    startAnimation();
    setTimeout(() => {
      navigate(path);
    }, 500); // Navigate after animations
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="background">
      <div className="gif"></div>
      <Container
        fluid
        className="p-2 mt-5"
        style={{ backgroundColor: "transparent" }}
      >
        <Row className="mt-5">
          <Col xs={12} sm={12} md={12} lg={5} xl={4} xxl={3}>
            <ProfileCircle animate={isAnimating}>
              <img
                src="https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/239368700_6669288189763963_3250199883287898218_n%20(1).jpg?updatedAt=1717595252143"
                alt="Profile pic"
                title="Profile pic"
              />
            </ProfileCircle>
          </Col>
          <Col xs={12} sm={12} md={12} lg={7} xl={8} xxl={9}>
            {window.innerWidth <= 991 && (
              <h4 style={{ position: "relative ", zIndex: "10" }}>
                Full Stack Engineer | Software Architect | Data Analyst
              </h4>
            )}
            <TitleText animate={isAnimating} showMore={showMore}>
              {windowWidth <= 991 ? (
                <span className="description">
                  Welcome to my professional website! I am a seasoned Full Stack
                  Developer and Software Architect with experience at top-tier
                  companies like Datto, TikTok, and Accenture. My work spans
                  from transitioning Datto's Portal website to React, enhancing
                  TikTok's content accuracy with advanced analytics, to
                  streamlining tasks at Accenture with custom Chrome extensions.
                  Academically, I hold a Bachelor's in Physics from Universidade
                  do Porto, graduating third out of 50 students, and specialized
                  in Nuclear Fusion Science at the University of Stuttgart and
                  Université de Lorraine, excelling in advanced plasma physics
                  and research. Transitioning to software development, I topped
                  my class in a FullStack Bootcamp at EPICODE, earned a Higher
                  Diploma in Computing with top honors from Atlantic
                  Technological University, and achieved an MSc in Software
                  Design with Cloud Native Computing from the Technological
                  University of the Shannon, mastering microservices
                  architecture and container deployment. My projects showcase
                  skills in cloud storage, microservices, real-time data, and
                  responsive web design. Feel free to explore my work and
                  contact me here or on LinkedIn.
                </span>
              ) : (
                <>
                  <h4 style={{ position: "relative ", zIndex: "10" }}>
                    Full Stack Engineer | Software Architect | Data Analyst
                  </h4>
                  <p className="description">
                    Welcome to my professional website! I am a seasoned Full
                    Stack Developer and Software Architect with experience at
                    top-tier companies like Datto, TikTok, and Accenture. My
                    work spans from transitioning Datto's Portal website to
                    React, enhancing TikTok's content accuracy with advanced
                    analytics, to streamlining tasks at Accenture with custom
                    Chrome extensions. Academically, I hold a Bachelor's in
                    Physics from Universidade do Porto, graduating third out of
                    50 students, and specialized in Nuclear Fusion Science at
                    the University of Stuttgart and Université de Lorraine,
                    excelling in advanced plasma physics and research.
                    Transitioning to software development, I topped my class in
                    a FullStack Bootcamp at EPICODE, earned a Higher Diploma in
                    Computing with top honors from Atlantic Technological
                    University, and achieved an MSc in Software Design with
                    Cloud Native Computing from the Technological University of
                    the Shannon, mastering microservices architecture and
                    container deployment. My projects showcase skills in cloud
                    storage, microservices, real-time data, and responsive web
                    design. Feel free to explore my work and contact me here or
                    on LinkedIn.
                  </p>
                </>
              )}
              <span
                className="toggle-button"
                onClick={toggleShowMore}
                style={{ fontSize: "0.5em" }}
              >
                {showMore ? "Show Less" : "View All"}
              </span>
            </TitleText>
          </Col>
        </Row>
        {sections.map((section, index) => (
          <Row className="mt-5" key={index}>
            <Section
              direction={section.direction}
              animate={isAnimating}
              onClick={() => handleSectionClick(section.path)}
            >
              <ImgPlaceholder
                direction={section.direction}
                imageURL={section.imageURL}
              />
              <div>
                <h4>{section.title}</h4>
                <p>{section.text}</p>
              </div>
            </Section>
          </Row>
        ))}
      </Container>
    </div>
  );
};

const sections = [
  {
    title: "Higher Education",
    text: "From Nuclear Fusion Engineering to Cloud Computing Design...",
    direction: "left",
    imageURL:
      "https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExaXpjcG9qeGs1bnF0Z3Z5czl2aDg0dnh2OWFweWxqdmp6dWpyaWo3bSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/26BkLTlD4lUInspxe/giphy.gif",
    path: "/education",
  },
  {
    title: "Professional Experience",
    text: "Datto, TikTok, Accenture... extensive experience in software development.",
    direction: "right",
    imageURL:
      "https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExZGVxZW91b3FsaXR5M2wzZ2JpenZ1bjJ5NTd0ajJxbmdyeGI2bjllZyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/l5JbspfwZ0yjHjlJ0K/giphy.gif",
    path: "/experience",
  },
  {
    title: "Projects",
    text: "Development of innovative projects and contributions to open-source platforms...",
    direction: "left",
    imageURL:
      "https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExNTc2Z29tbGJic3c2NzVtYzFwMTFtcWhlcDgxYXR2b3NvN2gxdmUycSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3o7ZeFcD0ZWsecY3bG/giphy.gif",
    path: "/projects",
  },
  {
    title: "Contact",
    text: "Send me a message or connect with me on Linkedin...",
    direction: "right",
    imageURL: "https://i.giphy.com/l41YvpiA9uMWw5AMU.webp",
    path: "/contact",
  },
];

export default Home;
