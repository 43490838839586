import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import "./style.css";

function Feed() {
  const navigate = useNavigate();
  const [showIntro, setShowIntro] = useState(true);
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const playerRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    // Load and initialize the YouTube player
    function loadYouTubeScript() {
      if (!window.YT || !window.YT.Player) {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = initializePlayer;
      } else {
        checkYTPlayerReadiness();
      }
    }

    function checkYTPlayerReadiness() {
      if (window.YT && window.YT.Player) {
        initializePlayer();
      } else {
        setTimeout(checkYTPlayerReadiness, 1000);
      }
    }

    let isInitializing = false;

    function initializePlayer() {
      if (
        window.YT &&
        window.YT.Player &&
        playerRef.current &&
        !player &&
        !isInitializing
      ) {
        isInitializing = true;
        try {
          const newPlayer = new window.YT.Player(playerRef.current, {
            videoId: "HuxYMC3t-EU",
            events: {
              onReady: (event) => {
                setPlayer(event.target);
                setPlayerLoaded(true);
                // Start checking if the video is playing
                startCheckingPlayerState();
                event.target.playVideo();
                setTimeout(() => {
                  event.target.pauseVideo();
                }, 100);
              },
              onStateChange: (event) => {
                if (event.data === window.YT.PlayerState.ENDED) {
                  navigate("/home");
                }
              },
            },
            playerVars: {
              autoplay: 1,
              controls: 0,
              modestbranding: 1,
              rel: 0,
              loop: 0,
              enablejsapi: 1,
              origin: window.location.origin,
              mute: 0,
            },
          });
          if (newPlayer) {
            setPlayer(newPlayer);
            isInitializing = false;
          }
        } catch (error) {
          console.error("Failed to create player", error);
          isInitializing = false;
        }
      }
    }

    function startCheckingPlayerState() {
      const id = setInterval(() => {
        if (
          player &&
          player.getPlayerState() !== window.YT.PlayerState.PLAYING
        ) {
          player.playVideo();
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
      setIntervalId(id);
    }

    loadYouTubeScript();

    return () => {
      clearInterval(intervalId); // Cleanup interval on component unmount
    };
  }, [navigate, player, intervalId]);

  const handleEnter = () => {
    setShowIntro(false);
    if (player && typeof player.playVideo === "function" && playerLoaded) {
      player.playVideo();
    }
  };

  const handleSkip = () => {
    if (player && typeof player.stopVideo === "function" && playerLoaded) {
      player.stopVideo();
    }
    navigate("/home");
  };

  return (
    <div>
      {showIntro && (
        <div className="intro-screen">
          <p>Turn on the sound</p>
          <h1>This is the website of Gustavo Pedro Ricou</h1>
          <button
            className="intro-button"
            onClick={handleEnter}
            disabled={!playerLoaded}
          >
            {playerLoaded ? "Start Intro" : "Loading..."}
          </button>
          {playerLoaded && (
            <button className="intro-button" onClick={handleSkip}>
              Skip Intro&gt;
            </button>
          )}
        </div>
      )}

      <div className="youtube-container" style={{ display: "block" }}>
        <div ref={playerRef} id="player"></div>
      </div>
      <button className="skip-button" onClick={handleSkip}>
        Skip
      </button>
    </div>
  );
}

export default Feed;
