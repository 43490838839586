const jobs = [
  {
    title: "Full Stack Developer",
    company: "Datto (Kaseya)",
    duration: "Jul 2023 - Jan 2024",
    location: "Cracow, Poland",
    start: "2023-07-01",
    end: "2024-01-01",
    imageUrls: [
      "https://embed-ssl.wistia.com/deliveries/650d67bfc4713b81b26a3e2aed693a33.webp?image_crop_resized=1280x720",
      "https://www.officemap.pl/application/assets/img/offices/high-5ive-2/high-five-2-6.jpeg",
    ],
    imageTitles: ["Datto Logo", "Kaseya Office"],
    duties: [
      "Created a Dropbox clone using Symfony (PHP), Lexik JWT, Doctrine ORM for MySQL connectivity on a VM with ZFS filesystem for the backend, and React with Bootstrap for the front end.",
      "Collaborated on the transition towards React of the Portal website and crafted reusable components for a DattoUI Storybook, enhancing the UI for customer’s BCDR process management and streamlining UI development.",
      "Authored unit tests using Jest for both Portal and DattoUI components, enhancing code reliability and aiding continuous integration, increasing test coverage from 80% to 90%.",
      "Engaged in daily standups and bi-weekly demos in an Agile (Scrum) environment, fostering team collaboration and continuous project feedback.",
      "Resolved spikes and bugs in the Portal website, undertaking a thorough analysis and improvement of the codebase.",
      "Optimized application performance by implementing caching mechanisms and conducting load testing.",
      "Designed and integrated RESTful APIs to enhance interoperability between front-end and back-end systems.",
      "Implemented CI/CD pipelines using Jenkins and Docker to streamline the deployment process and ensure rapid delivery of updates.",
      "Developed and maintained detailed technical documentation for new features and system enhancements.",
    ],
    skills: [
      "Symfony",
      "React",
      "Bootstrap",
      "Doctrine ORM",
      "MySQL",
      "Jest",
      "Agile (Scrum)",
      "JavaScript",
      "PHP",
      "ZFS",
      "Ubuntu Server",
      "API Development",
      "CI/CD",
      "Git",
      "Unit Testing",
      "Microservices",
      "Cloud Storage Solutions",
      "Docker",
      "Jenkins",
      "RESTful APIs",
      "Performance Optimization",
      "Caching Mechanisms",
      "Load Testing",
      "Technical Documentation",
      "HTML5",
      "CSS3",
      "Webpack",
      "Redux",
      "TypeScript",
      "Sass",
      "Node.js",
      "Express.js",
      "Continuous Integration",
      "Continuous Deployment",
      "MongoDB",
      "Kubernetes",
      "Serverless Architectures",
      "OAuth",
      "GraphQL",
      "TDD (Test-Driven Development)",
    ],
    mapLocation: "High 5ive TWO, Krakow, Poland",
  },
  {
    title: "Research Intern",
    company: "Technological University of the Shannon",
    duration: "Jan 2023 - Jul 2023",
    location: "Athlone, Ireland",
    start: "2023-01-01",
    end: "2023-07-01",
    imageUrls: [
      "https://media.licdn.com/dms/image/C4E1BAQFEln7gN4Mv5A/company-background_10000/0/1633103779215/tusmidlands_cover?e=2147483647&v=beta&t=qNcaWOrccR6wZ07bYzzY6Ts3A2p0RNkucfPUzZ2xhIA",
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/TUS_Athlone_Engineering_%26_Science_Building%2C_2022-12-20.jpg/1200px-TUS_Athlone_Engineering_%26_Science_Building%2C_2022-12-20.jpg",
    ],
    imageTitles: ["TUS Logo", "TUS Engineering & Science Building"],
    duties: [
      "Developed CarSurfing, a cloud-based carpooling service using microservice architecture to ensure scalability and accessibility.",
      "Utilized Java and Spring Boot within Docker environments for efficient service scalability and management.",
      "Managed database interactions with SQL and MySQL under AWS cloud infrastructure, ensuring reliable data handling and storage.",
      "Implemented a continuous integration and continuous deployment (CI/CD) pipeline using GitHub and Jenkins for streamlined deployment processes.",
      "Conducted in-depth performance testing and optimization of microservices to ensure high availability and responsiveness.",
      "Collaborated with a cross-functional team to design and develop a user-friendly front-end using Vue.js and Tailwind CSS.",
      "Integrated third-party services such as Google Maps to enhance the functionality and user experience of the application.",
      "Provided detailed documentation and user training to facilitate smooth onboarding and usage of the CarSurfing platform.",
    ],
    skills: [
      "Java",
      "Spring Boot",
      "Docker",
      "SQL",
      "MySQL",
      "AWS",
      "CI/CD",
      "GitHub",
      "Jenkins",
      "Performance Testing",
      "Microservices",
      "Vue.js",
      "Tailwind CSS",
      "Google Maps API",
      "Technical Documentation",
      "User Training",
      "Cross-Functional Collaboration",
      "Cloud Computing",
      "Continuous Deployment",
      "Data Management",
      "Service Scalability",
    ],
    mapLocation:
      "TUS: Midlands Engineering & Science Building, Athlone, Ireland",
  },
  {
    title: "Senior Sampling Quality Analyst",
    company: "TikTok",
    duration: "Oct 2020 - Jun 2023",
    location: "Dublin, Ireland",
    start: "2020-10-01",
    end: "2023-06-01",
    imageUrls: [
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-Bk3F5U7mVnpbV2awvXrVYppJI6al_Qb-ig&s",
      "https://cdn-images.wework.com/images/81BE62CA-BD12-11E9-8DF0-0A80A47AA582/81be62ca-bd12-11e9-8df0-0a80a47aa582_0.jpg?width=540",
    ],
    imageTitles: ["TikTok Logo", "TikTok Office"],
    duties: [
      "Oversaw and enhanced critical tracking systems and dashboards, leveraging advanced data visualization techniques to support strategic decision-making.",
      "Acted as the primary liaison for high-importance accounts, significantly enhancing client relations, retention, and satisfaction.",
      "Resolved complex system issues by optimizing performance and implementing efficiency improvements, ensuring seamless operational functionality.",
      "Led the English hashtag analysis team, achieving a substantial increase in content quality metrics over a three-year period.",
      "Performed advanced data analytics using Python and SQL to refine and streamline content review processes and policies.",
      "Facilitated key communications and policy updates with global vendors, maintaining high quality standards and ensuring compliance with company guidelines.",
      "Collaborated with cross-functional teams to develop and implement quality assurance protocols, enhancing overall content integrity and user experience.",
    ],
    skills: [
      "Data Analytics",
      "Python",
      "SQL",
      "Client Relations",
      "System Optimization",
      "Data Visualization",
      "Quality Assurance",
      "Dashboard Development",
      "Content Review",
      "Policy Implementation",
      "Cross-Functional Collaboration",
      "Vendor Management",
      "Strategic Decision-Making",
      "Problem-Solving",
      "Performance Enhancement",
      "Communication Skills",
      "Project Management",
      "Process Improvement",
      "Technical Documentation",
    ],
    mapLocation: "WeWork 5 Harcourt Road, Dublin, Ireland",
  },
  {
    title: "JavaScript Automation Engineer & Process Improvement Specialist",
    company: "Accenture (Client: Google)",
    duration: "Jan 2019 - Aug 2019",
    location: "Cracow, Poland",
    start: "2019-01-01",
    end: "2019-08-01",
    imageUrls: [
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Accenture.svg/2560px-Accenture.svg.png",
      "https://officepoland.pl/wp-content/uploads/1095-biuro-do-wynajecia-krakow-pradnik-czerwony-tertium-business-park-1-a-01-officepoland-pl.jpg",
    ],
    imageTitles: ["Accenture Logo", "Tertium Business Park - Google Office"],
    duties: [
      "Developed advanced JavaScript automation scripts for analysing DOM structures and simulating in-browser events, significantly enhancing web safety, compliance, and performance.",
      "Designed and deployed robust Chrome extensions to automate and streamline content verification tasks, improving efficiency and accuracy in large-scale web projects.",
      "Mentored new hires and junior developers in web technologies, fostering skill development and enhancing the team's overall performance and knowledge base.",
      "Identified and addressed workflow inefficiencies through detailed process analysis, implementing solutions that resulted in improved operational workflows and project delivery timelines.",
      "Collaborated with cross-functional teams to ensure seamless integration of automation tools and processes, enhancing overall project quality and client satisfaction.",
      "Implemented and maintained automated testing frameworks to ensure the reliability and quality of web applications, reducing manual testing efforts and enhancing deployment efficiency.",
    ],
    skills: [
      "JavaScript",
      "Automation",
      "Chrome Extensions",
      "Web Safety",
      "Mentoring",
      "Process Improvement",
      "DOM Manipulation",
      "In-Browser Event Simulation",
      "Content Verification",
      "Operational Efficiency",
      "Cross-Functional Collaboration",
      "Automated Testing",
      "Selenium",
      "Puppeteer",
      "Continuous Integration/Continuous Deployment (CI/CD)",
      "Agile Methodologies",
      "Jenkins",
      "Team Leadership",
      "Problem-Solving",
      "Project Management",
      "Technical Documentation",
      "Code Review",
      "Performance Optimization",
      "Client Relations",
      "Data Analysis",
    ],
    mapLocation: "Tertium Business Park, Cracow, Poland",
  },
  {
    title:
      "Senior JavaScript Automation Engineer & Process Improvement Specialist",
    company: "Arvato Finance (Client: Google)",
    duration: "Jun 2017 - Dec 2018",
    location: "Cracow, Poland",
    start: "2018-06-01",
    end: "2018-12-01",
    imageUrls: [
      "https://finance.arvato.com/49df2d/globalassets/01-images/01-homepage/logo-financial-solutions-original-rgb.jpg?width=950&mode=min",
      "https://pasaz13.home.pl/images/p133.jpg",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Arvato1.jpg?updatedAt=1718244314227",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Arvato2.jpg?updatedAt=1718244314225",
    ],
    imageTitles: [
      "Arvato Logo",
      "Google Office Entrance",
      "Arvato Christmas Party",
      "Arvato Laser Tag Team Building",
    ],
    duties: [
      "Developed advanced JavaScript scripts and Chrome extensions for automated detection and removal of hidden scripts in websites and mobile apps, significantly improving web security.",
      "Led cross-functional collaboration with UI/UX designers, backend developers, and project managers to ensure seamless integration and implementation of JavaScript functionalities in high-impact Google projects.",
      "Achieved a quality assurance score exceeding 98% through the implementation of performance enhancements and strict compliance with web safety standards.",
      "Streamlined development processes and reduced project turnaround times by implementing best practices in automation and process improvement.",
      "Conducted comprehensive code reviews and provided mentorship to junior developers, fostering a culture of continuous improvement and technical excellence.",
    ],
    skills: [
      "JavaScript",
      "Chrome Extensions",
      "UI/UX Collaboration",
      "Quality Assurance",
      "Web Safety",
      "Automation",
      "Process Improvement",
      "Cross-Functional Team Leadership",
      "Performance Optimization",
      "Code Reviews",
      "Mentorship",
      "Software Development Life Cycle (SDLC)",
      "Project Management",
      "Agile Methodologies",
      "Problem-Solving",
      "Technical Documentation",
      "Continuous Integration/Continuous Deployment (CI/CD)",
      "Debugging",
      "Version Control (Git)",
      "Customer Requirements Analysis",
    ],
    mapLocation: "Google, Cracow, Poland",
  },
  {
    title: "System Analyst & Back-end Developer",
    company: "Vertu",
    duration: "Apr 2016 - May 2017",
    location: "Athens, Greece",
    start: "2016-04-01",
    end: "2017-05-01",
    imageUrls: [
      "https://upload.wikimedia.org/wikipedia/commons/e/ec/Vertu_England_Logo.png",
      "https://spaces.imgix.net/mediaFiles/Ni8wL2QvZi82MGRmZTkzMzE4YzhiZjBjZGY0OTAyZGMzNTE4YWUwY2ZkMjUzY2I5X1NwYWNlc19Mb25kb25DaXR5Um9hZF9Gb2N1c0FyZWF3aXRoUGVvcGxlLmpwZy9kb3dubG9hZA?w=910&h=420&q=30&auto=format,compress",
    ],
    imageTitles: ["Vertu Logo", "Vertu Office"],
    duties: [
      "Conducted in-depth analysis of customer interaction data with Vertu's luxury phone services, using SQL for data extraction, Python with NumPy and SciPy for advanced analysis, and MATLAB for simulation and modelling, dissecting user behaviour across over 5,000 customer accounts. These insights led to targeted system enhancements that significantly elevated the user experience.",
      "Crafted comprehensive system documentation and interactive Excel dashboards for monitoring Vertu’s luxury service usage, delivering detailed monthly reports to stakeholders, and providing training support to internal teams. This enhanced customer service delivery and stakeholder communication.",
      "Managed AWS cloud infrastructures and Linux server environments to support high-availability mobile applications, ensuring optimal performance and scalability to meet rigorous service-level agreements.",
      "Utilized Java Spring to develop robust backend analysis tools, facilitating the comprehensive examination of customer interaction data. Additionally, developed JavaScript-driven Chrome extensions to automate routine internal system analysis tasks, enhancing operational efficiency.",
      "Led the integration of cutting-edge technologies and best practices into the system development lifecycle, contributing to improved system reliability and performance.",
      "Collaborated with cross-functional teams to align technical solutions with business objectives, ensuring cohesive and effective project execution.",
      "Provided expert guidance on data-driven decision-making processes, leveraging advanced analytics to inform strategic planning and operational improvements.",
    ],
    skills: [
      "SQL",
      "Python",
      "NumPy",
      "SciPy",
      "MATLAB",
      "Java Spring",
      "JavaScript",
      "Chrome Extensions",
      "AWS",
      "Linux",
      "Customer Service",
      "Data Analysis",
      "Simulation",
      "System Documentation",
      "Excel Dashboards",
      "Stakeholder Communication",
      "Training Support",
      "High-Availability Systems",
      "Operational Efficiency",
      "Cross-Functional Collaboration",
      "Technical Leadership",
      "Data-Driven Decision Making",
      "Strategic Planning",
      "Project Management",
      "Problem-Solving",
      "Numerical Analysis",
      "Microsoft Office",
      "MySQL",
      "Public Speaking",
      "English",
    ],
    mapLocation: "Marikas Kotopouli 1, Athina 104 31, Greece",
  },
  {
    title: "Technical Support Engineer",
    company: "Teleperformance (Client: Microsoft)",
    duration: "Oct 2015 - May 2016",
    location: "Athens, Greece",
    start: "2015-10-01",
    end: "2016-05-01",
    imageUrls: [
      "https://jobsteleperformance.com/wp-content/uploads/2023/07/GMT_Logos-Teleperformance_RGB_July-2023_purple-gradient.png",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Athens1.jpg?updatedAt=1718242558658",
    ],
    imageTitles: [
      "Teleperformance Logo",
      "Teleperformance Microsoft Office with Dimitri Paschou",
    ],
    duties: [
      "Diagnosed and resolved complex technical issues with Microsoft products by creating and running advanced SQL queries to retrieve and analyze critical data.",
      "Supported both Linux and Windows server operations in Microsoft environments, ensuring system availability and addressing intricate configurations and software issues.",
      "Delivered exceptional bilingual customer service in English and French, translating technical jargon into comprehensible guidance, resolving issues efficiently to maintain high customer satisfaction and minimize downtime.",
      "Enhanced system performance and streamlined user support by developing and refining JavaScript scripts for automated task handling and issue resolution.",
      "Created and optimized Microsoft Office-based support processes, contributing to operational efficiency and effective knowledge management.",
      "Assisted in the training and mentoring of new team members, sharing best practices and technical expertise to improve team performance.",
    ],
    skills: [
      "SQL",
      "Linux",
      "Windows Server",
      "Customer Service",
      "JavaScript",
      "Microsoft Office",
      "Technical Troubleshooting",
      "Bilingual Communication",
      "System Optimization",
      "Automated Task Handling",
      "Data Analysis",
      "Configuration Management",
      "Technical Mentoring",
      "Problem Resolution",
      "Knowledge Management",
      "Network Support",
      "Software Support",
      "System Performance Tuning",
      "Technical Documentation",
    ],
    mapLocation: "Teleperformance, Athens, Greece",
  },
  {
    title: "Research Intern",
    company: "Institut Jean Lamour (IJL)",
    duration: "Mar 2015 - Oct 2015",
    location: "Nancy, Grand Est, France",
    start: "2015-03-01",
    end: "2015-10-01",
    imageUrls: [
      "https://ijl.univ-lorraine.fr/sites/default/files/commun/Espace%20media/logo_IJL_Noir.jpg",
      "https://www.centre-est.cnrs.fr/sites/delegation_dr6/files/inline-images/cnrs_20180015_0093%20%281%29.jpg",
    ],
    imageTitles: [
      "Institut Jean Lamour Logo",
      "IJL Laboratory (ultra-high vacuum deposition and analysis of nanomaterials)",
    ],
    duties: [
      "Led synthetic diagnostic studies on reflectometry, utilizing Python and MATLAB to analyze and simulate plasma turbulence in magnetic fusion devices, significantly enhancing the understanding of plasma behavior.",
      "Collaborated with a multidisciplinary team of researchers to develop innovative diagnostic techniques, improving the accuracy and efficiency of plasma turbulence measurements.",
      "Implemented advanced data analysis algorithms to process large datasets from reflectometry experiments, optimizing the simulation models and contributing to cutting-edge research in magnetic fusion.",
      "Authored comprehensive research reports and presented findings at international conferences, showcasing the advancements in reflectometry and plasma turbulence diagnostics.",
      "Engaged in continuous learning and professional development, mastering new techniques and tools to support high-impact research projects.",
    ],
    skills: [
      "Reflectometry",
      "Plasma Turbulence",
      "Python",
      "MATLAB",
      "Data Analysis",
      "Simulation",
      "Scientific Programming",
      "Research Reporting",
      "Algorithm Development",
      "Data Visualization",
      "Collaborative Research",
      "Magnetic Fusion",
      "Advanced Diagnostics",
      "High-Performance Computing",
      "Numerical Modeling",
    ],
    mapLocation: "Institut Jean Lamour, Nancy, Grand Est, France",
  },

  {
    title: "Research Intern",
    company:
      "Commissariat à l'Énergie Atomique et aux Énergies Alternatives (CEA) Cadarache",
    duration: "Jan 2015 - Apr 2015",
    location: "Aix-en-Provence, France",
    start: "2015-01-01",
    end: "2015-04-01",
    imageUrls: [
      "https://www.pagesjaunes.fr/media/agc/e5/75/40/00/00/d8/26/d8/6e/51/5d35e575400000d826d86e51/5d35e575400000d826d86e52.png",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/CEA1.jpg?updatedAt=1718241249730",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/CEA3.jpg?updatedAt=1718241249713",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/CEA2.jpg?updatedAt=1718241249749",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/photo_reflect_CEA.jpg?updatedAt=1718110856586",
    ],
    imageTitles: [
      "CEA Cadarache Logo",
      "Tore Supra Tokamak and the research group",
      "ITER site",
      "Bump-On-Tail Instability group, from left to right, Gustavo Pedro Ricou, Iaroslav Morgal, Jeffery Zielinski",
      "The reflectometry group, with our supervisor Dr. Frederic Clairet",
    ],
    duties: [
      "Led reflectometry research studies on the Tore Supra Tokamak, under the supervision of Dr. Frederic Clairet, analyzing plasma density profiles and fluctuations to advance the understanding of plasma behavior.",
      "Developed and optimized Python script, under the supervision of Dr. Yanick Sarazin, to process and visualize data from Fortran-based Bump-On-Tail Instability simulations, enhancing data accuracy and efficiency.",
      "Collaborated with senior researchers to refine diagnostic techniques and improve plasma control methodologies, contributing to significant advancements in fusion energy research.",
      "Authored detailed technical reports and presented findings to the scientific community, demonstrating innovative solutions and methodologies in plasma diagnostics.",
      "Engaged in cross-disciplinary team projects, leveraging expertise in reflectometry and numerical simulations to support various experimental setups and research objectives.",
    ],
    skills: [
      "Reflectometry",
      "Tore Supra Tokamak",
      "Python",
      "Fortran",
      "Plasma Physics",
      "Data Analysis",
      "Numerical Simulations",
      "Scientific Programming",
      "Research Reporting",
      "Technical Presentation",
      "Team Collaboration",
      "Experimental Physics",
      "NumPy",
      "SciPy",
      "MATLAB",
      "High-Performance Computing",
      "Data Visualization",
      "Algorithm Optimization",
    ],
    mapLocation: "CEA Cadarache, Aix-en-Provence, France",
  },
  {
    title: "Research Intern",
    company: "Institute of Plasma Physics of the Czech Academy of Sciences",
    duration: "Nov 2014 - Jan 2015",
    location: "Prague, Czech Republic",
    start: "2014-11-01",
    end: "2015-01-01",
    imageUrls: [
      "https://www.ipp.cas.cz/miranda2/export/sitesavcr/ufp/o-ufp/visual_identity/IPP_-RGB-_EN_White_Horisontal_Long.png",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Compass_Tokamak.jpg?updatedAt=1718114690268",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Prague2.jpg?updatedAt=1718240349384",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Prague1.jpg?updatedAt=1718240349313",
    ],
    imageTitles: [
      "Institute of Plasma Physics Logo",
      "COMPASS Tokamak and Gustavo Pedro Ricou",
      "COMPASS Tokamak, Ahmed Omar and Gustavo Pedro Ricou",
      "Gustavo Pedro Ricou and the GOLEM Tokamak (the oldest operational tokamak in the world)",
    ],
    duties: [
      "Conducted comprehensive research on plasma disruptions in the COMPASS Tokamak under the guidance of renowned supervisors J. Havlicek, O. Hronova, and A. Dvornova.",
      "Analyzed disruption events using advanced data analysis techniques in Python to improve diagnostic processes and plasma control methods.",
      "Developed and implemented data acquisition and processing scripts to study the Greenwald density limit and magnetic island formations.",
      "Collaborated with a team of researchers to perform high-precision experiments, contributing to significant advancements in understanding plasma instabilities.",
      "Prepared detailed research reports and presentations, highlighting key findings and potential areas for future research.",
    ],
    skills: [
      "Plasma Physics",
      "Data Analysis",
      "Python",
      "NumPy",
      "SciPy",
      "Tokamak Operations",
      "Magnetic Diagnostics",
      "Greenwald Limit Studies",
      "Magnetic Island Analysis",
      "Signal Processing",
      "High-Precision Measurement",
      "Automated Control Systems",
      "Research Reporting",
      "Scientific Communication",
    ],
    mapLocation: "Institute of Plasma Physics, Prague, Czech Republic",
  },

  {
    title: "Research Assistant - Student Job",
    company:
      "Institut für Grenzflächenverfahrenstechnik und Plasmatechnologie, Universität Stuttgart",
    duration: "Dec 2013 - Aug 2014",
    location: "Stuttgart, Germany",
    start: "2013-12-01",
    end: "2014-08-01",
    imageUrls: [
      "https://www.igvp.uni-stuttgart.de/system/modules/de.stuttgart.uni.v3.basics/resources/img/svg/logo-inverted-de.svg",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Stuttgart7.jpg?updatedAt=1718236580569",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Stuttgart2.jpg?updatedAt=1718218279068",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Photos/Stuttgart5.jpg?updatedAt=1718218279070",
    ],
    imageTitles: [
      "Institut für Grenzflächenverfahrenstechnik und Plasmatechnologie, Universität Stuttgart Logo",
      "Entrance hall to the institute",
      "From left to right, Gustavo Pedro Ricou, Guillermo Suarez, Egor Seliunin and Pranay Valson, at Institut für Grenzflächenverfahrenstechnik und Plasmatechnologie, Universität Stuttgart",
      "In Pont-à-Mousson, France, with our supervisor Dr. Carsten Lechte.",
    ],
    duties: [
      "Collaborated with Dr. Carsten Lechte, Leader of Microwave Technology Group, to enhance and refine educational content for Numerical Plasma Physics courses.",
      "Transcribed complex course notes into LaTeX using Lyx, ensuring clarity and precision in mathematical notation and scientific diagrams.",
      "Developed comprehensive educational materials, including detailed graphs and diagrams, to support the understanding of numerical solutions of ODEs and PDEs related to plasma physics.",
      "Created programmatic data visualizations and simulations using Python to illustrate concepts such as plasma turbulence and wave propagation.",
      "Improved the quality and accessibility of course content, benefiting future cohorts of students in their study of Numerical Plasma Physics.",
      "Implemented best practices in scientific programming and data visualization to create engaging and informative educational resources.",
    ],
    skills: [
      "LaTeX",
      "Lyx",
      "Python",
      "NumPy",
      "SciPy",
      "Numerical Analysis",
      "Scientific Programming",
      "Data Visualization",
      "Graphical Design",
      "Mathematical Notation",
      "ODEs",
      "PDEs",
      "Plasma Physics",
      "Plasma Turbulence",
      "Wave Propagation",
      "Educational Material Development",
      "Attention to Detail",
      "Content Clarity Enhancement",
    ],
    mapLocation:
      "Institut für Grenzflächenverfahrenstechnik und Plasmatechnologie, Stuttgart, Germany",
  },
  {
    title: "Tutor",
    company: "AEFCUP - Associação de Estudantes da FCUP",
    duration: "Jan 2010 - Sep 2013",
    location: "Porto, Portugal",
    start: "2010-01-01",
    end: "2013-09-01",
    imageUrls: [
      "https://upload.wikimedia.org/wikipedia/commons/f/fd/Log%C3%B3tipo_da_AEFCUP.png",
      "https://upload.wikimedia.org/wikipedia/commons/4/41/DCC_e_sede_AEFCUP.jpg",
    ],
    imageTitles: ["AEFCUP Logo", "AEFCUP Building"],
    duties: [
      "Tutored over 100 science and engineering students in mathematics, programming (Python), and physics, consistently enhancing their academic performance and problem-solving skills.",
      "Developed personalized tutoring plans tailored to each student's learning style, resulting in significant improvement in their grades and understanding of complex concepts.",
      "Received continuous referrals through word-of-mouth, thanks to the high success rate and satisfaction of students.",
      "Achieved a remarkable track record with no student failing an exam under my guidance.",
      "Organized and conducted group study sessions and exam preparation workshops, fostering a collaborative learning environment.",
      "Created comprehensive study materials and resources that were widely used and appreciated by students for exam preparations.",
      "Provided mentorship and academic counseling, helping students set and achieve their educational goals.",
      "Utilized innovative teaching methods and tools to make learning engaging and effective.",
    ],
    skills: [
      "Tutoring",
      "Mathematics",
      "Python",
      "Physics",
      "Problem-Solving",
      "Personalized Learning Plans",
      "Academic Mentorship",
      "Collaborative Learning",
      "Study Material Creation",
      "Innovative Teaching Methods",
      "Student Engagement",
      "Exam Preparation",
      "Mentorship",
      "Educational Counseling",
      "Communication Skills",
      "Linear Algebra",
      "Calculus",
      "Electromagnetism",
      "Classical Mechanics",
      "Quantum Mechanics",
      "Statistics",
      "Differential Equations",
      "Analytical Geometry",
    ],
    mapLocation: "FCUP Department of Computer Sciences, Porto, Portugal",
  },

  {
    title: "Research Intern",
    company:
      "IFIMUP - Instituto de Física de Materiais Avançados, Nanotecnologia e Fotónica",
    duration: "Sep 2011 - Feb 2012",
    location: "Porto, Portugal",
    start: "2011-09-01",
    end: "2012-02-01",
    imageUrls: [
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/IFIMUP-IN.gif/799px-IFIMUP-IN.gif",
      "https://media.licdn.com/dms/image/C4D1BAQFV7mRrIIvL1A/company-background_10000/0/1596216762409/ifimup_cover?e=2147483647&v=beta&t=AOaefzBY5hZceE0EyAqa9bTcu6UH2H-oSwYna6JvS9M",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/LabView2.png?updatedAt=1718115470572",
      "https://ik.imagekit.io/Gustolandia/Portfolio%20Website/Snippets/LabView.png?updatedAt=1718115470441",
    ],
    imageTitles: [
      "IFIMUP Logo",
      "IFIMUP Laboratory",
      "Some of the control dashboards developed",
      "Automation work",
    ],
    duties: [
      "Managed automatic control and data acquisition systems for the study of electrical susceptibility and thermally stimulated currents in ceramics and polarizable films, significantly advancing the research capabilities of the institute.",
      "Developed a comprehensive system for automatic data control and acquisition using NI LabVIEW, integrating various instruments for precise measurements and data logging.",
      "Conducted experimental setups and measurements in advanced materials research, focusing on ceramics and polarizable films.",
      "Collaborated with senior researchers to optimize experimental protocols and improve data accuracy.",
      "Presented research findings at internal seminars and contributed to academic publications.",
      "Maintained and calibrated laboratory equipment to ensure accurate and reliable measurements.",
      "Provided technical support and training to new interns and students on data acquisition systems and experimental techniques.",
    ],
    skills: [
      "NI LabVIEW",
      "G",
      "Data Acquisition",
      "Electrical Susceptibility",
      "Thermally Stimulated Currents",
      "Ceramics",
      "Polarizable Films",
      "Impedance Analysis",
      "Signal Processing",
      "High-Precision Measurement",
      "Automated Control Systems",
      "Experimental Design",
      "Data Analysis",
      "Scientific Research",
      "Team Collaboration",
      "Technical Writing",
      "Public Speaking",
      "Lab Equipment Maintenance",
      "Research Presentation",
      "Project Management",
      "Problem Solving",
      "Attention to Detail",
    ],
    mapLocation: "Departamento de Física e Astronomia FCUP, Porto, Portugal",
  },
];

export default jobs;
